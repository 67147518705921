import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { getApi } from '../../service/adminService';
import { Breadcrumbs } from '../../../components';
import Search from '../../../components/Search/Search';
import Loader from '../../../components/Loader/loader';
import PaginationComponent from '../../../PaginationComponent';
import { TimeZone } from '../../../components/TimeZone/TimeZone';

const MLanalysislogs = () => {
    const [datasArray, setDatasArray] = useState([]);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [search, setSearch] = useState('');
    const [sortedBy, setSortedBy] = useState("updated_at")
    const [orderBy, setOrderBy] = useState('desc')
    const admin = JSON.parse(localStorage.getItem("admin"));
    const [loader, setloader] = useState(true)
    const [Count, setCount] = useState();
    const [ValidPagination, setValidPagination] = useState(false)

    const GetEmployeeLoginLogs = async (token) => {
        // const response = await getEmployeeLoginlogs(token, search, pagesCount, sortedBy, orderBy);
        const response = await getApi("/ml-error-logs", { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy });

        console.log(response);
        if (response?.status) {
            setTimeout(() => {
                setDatasArray(response.data.rows)
                setTotalPagesCount(response.data.count)
                setCount(response.data.count)
                setloader(false)
            }, 2000);
            setloader(true)
        }

        else {
            console.log("get merchant data response", response);
        }
    }
    useEffect(() => {
        GetEmployeeLoginLogs(admin.admin_token)
    }, [search, pagesCount, sortedBy, orderBy])

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }
    return (
        <>
            <div className="main-container container-fluid px-0">
                <div className="page-header mb-3">
                    <div className="page-leftheader">
                        {/* <h4 className="page-title mb-0 text-primary">Stores</h4> */}
                        <h4 className="page-title mb-0 text-primary">
                            {/* <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link className='breadcrumbslink' to="/merchant/dashboard" >Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Stores</li>
                        </ol>
                    </nav> */}
                            {/* <Breadcrumbs order={{ first: { name: "Employee Logs" } }} /> */}
                            <Breadcrumbs order={{ first: { name: "Logs" }, second: { name: `ML Analysis Error Logs ${Count ? '(' + Count + ')' : ''}` } }} />

                        </h4>
                    </div>
                    {/* <div className="page-rightheader">
                        <div className="btn-list">
                            <Link className="btn btn-outline-primary" ><i className="fa fa-download"></i> Download</Link>                    </div>
                    </div> */}
                </div>

                <div className="row">
                    {/* <div className="col-sm-12 col-lg-12">
                <div className="card">
                    <div className="card-header br-0">
                        <h3 className="card-title">Counter List</h3>
                    </div>
                </div>
            </div> */}
                </div>
                <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />

                {/* <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                    <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                        <div className="form-group">
                            <label className="form-label">Search</label>
                            <div className="row g-xs">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                    <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="card-body p-0">
                    <div className="table-responsive">
                        <table className="table table-hover card-table table-vcenter text-nowrap" >
                            <thead className="border-bottom-0 pt-3 pb-3">
                                <tr>
                                    <th className="font-weight-bold">Loan ID</th>
                                    {/* <th className="font-weight-bold">Customer ID   {sortedBy == "id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('id') }}></i>}</th> */}
                                    <th className="font-weight-bold">Borrower Detail   {sortedBy == "lender_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('lender_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('lender_id') }}></i>}</th>
                                    <th className="font-weight-bold">Lender Detail {sortedBy == "user_loan_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('user_loan_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('user_loan_id') }}></i>}</th>
                                    <th className="font-weight-bold">Message  {sortedBy == "error" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('error') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('error') }}></i>}</th>
                                    <th className="font-weight-bold"> Created Date  {sortedBy == "created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10, cursor: 'pointer' }} onClick={() => { sortChange('created_at') }}></i>}</th>
                                    {/* <th className="font-weight-bold"> </th> */}
                                    {/* <th className="font-weight-bold"> </th> */}
                                </tr>
                            </thead>
                            {loader == true ? <tbody>
                                <td /><td />
                                <div >
                                    <Loader /> </div> </tbody> :
                                <>
                                    <tbody>
                                        {datasArray?.length > 0 && datasArray?.map((option, i) => {
                                            let serial_num = ((10 * (pagesCount - 1)) + i + 1);
                                            const { user_loan_id, error } = option;
                                            return (
                                                <tr key={serial_num}>
                                                    <td>{user_loan_id ? user_loan_id : "--"}</td>
                                                    <td>{option?.loanApplication?.first_name ? option?.loanApplication?.first_name : "--"} {option?.loanApplication?.last_name ? option?.loanApplication?.last_name : "--"}
                                                        <br />{option?.loanApplication?.mobile_number ? option?.loanApplication?.mobile_number : "--"}
                                                    </td>
                                                    <td>{option?.lenderData.company_name ? option?.lenderData.company_name : "--"}
                                                        <br />{option?.lenderData.mobile_number ? option?.lenderData.mobile_number : "--"}
                                                        <br />{option?.lenderData.email ? option?.lenderData.email : "--"}
                                                    </td>
                                                    <td className='wrapCustomCss' style={{ width: '41rem' }}> {error ? error : "--"} </td>
                                                    <td>{TimeZone(option.created_at).timeZone}</td>
                                                </tr>
                                            )
                                        })
                                        }
                                    </tbody>
                                    <tbody>
                                        {datasArray.length == 0 &&
                                            <tr>
                                                <td colSpan={10} className='text-center'>No Data Available</td>
                                            </tr>
                                        }
                                    </tbody></>}
                        </table>
                    </div>
                </div>
                {pagesCount > 0 && datasArray.length > 0 && ValidPagination === true ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}
                {pagesCount > 0 && datasArray.length > 0 && ValidPagination === false ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}
            </div>
        </>
    )
}

export default MLanalysislogs
