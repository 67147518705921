import Header from '../website/header';
import Footer from '../website/footer';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Findmore, TalktoUS } from './Service/webservice';
import { toast } from 'react-hot-toast';
import { useRef } from 'react';
import { Link } from 'react-router-dom';

const Index = () => {
	const ref = useRef()
	const ref2 = useRef()
	const regex = /[A-Za-z]/;

	const form = useFormik({
		initialValues: {
			name: "",
			email: "",
			mobile_number: "",
			comment: "",
			//   company_name: "",
			//   company_number:"",
			wrong_message: ""
		},
		enableReinitialize: true,
		validationSchema: yup.object({
			name: yup.string().required('Please Enter Full Name')/* .matches(regex, 'Please Enter Only Character Values') */,
			email: yup.string().email("Invalid Email Address Format").required('Please Enter Email Address'),
			mobile_number: yup.string().required('Please Enter Mobile Number')/* .matches(phoneRegExp, 'Please enter only number') */.min(7, 'Mobile Number Must Contain 7 Number').max(15, 'Mobile Number Max Contain 15 Number'),
			comment: yup.string().required('Please Enter Comment'),
			//   company_name: yup.string().required('Please Enter Company Name'),	
			//   company_number: yup.string().required('Please Enter Company No.'),	
		}),
		onSubmit: async (values, { resetForm }) => {
			console.log("🚀 ~ file: login.js:166 ~ Login ~ values:", values)
			const data = JSON.stringify({
				name: values.name,
				email: values.email,
				mobile_number: `${values.mobile_number}`,
				comment: values.comment,
				// company_name: values.company_name,
				// company_number: values.company_number
			})
			// console.log(data)
			const response = await TalktoUS(data);

			// console.log("#@#@#", response);
			resetForm({ values: '' });
			ref.current.click()
			ref2.current.click()
			if (response.status == false) {
				console.log(response.message);
				toast.error(response.message);
				ref.current.click()
				ref2.current.click()
			}
			if (response.status == true) {
				console.log(response.message);
				toast.success(response.message);
				// window.$('#modal-center').modal('hide')	 
				ref.current.click()
				ref2.current.click()
			} else {

				console.log("#@#@#1234", response);
			}

		}
	});

	const Addform = useFormik({
		initialValues: {
			name: "",
			email: "",
			mobile_number: "",
			comment: "",
			company_name: "",
			company_number: "",
			wrong_message: ""

		},
		enableReinitialize: true,
		validationSchema: yup.object({
			name: yup.string().required('Please Enter Full Name')/* .matches(regex, 'Please Enter Only Character Values') */,
			email: yup.string().email("Invalid Email Address Format").required('Please Enter Email Address'),
			mobile_number: yup.string().required('Please Enter Mobile Number')/* .matches(phoneRegExp, 'Please enter only number') */.min(7, 'Mobile Number Must Contain 7 Number').max(15, 'Mobile Number Max Contain 15 Number'),
			company_name: yup.string().required('Please Enter Company Name'),
			company_number: yup.string().required('Please Enter Company No.')
		}),

		onSubmit: async (values, { resetForm }) => {
			const data = JSON.stringify({
				name: values.name,
				email: values.email,
				mobile_number: `${values.mobile_number}`,
				company_name: values.company_name,
				company_number: values.company_number

			});

			const response = await Findmore(data)
			resetForm({ values: '' });
			if (response.status == true) {
				console.log('inside ', response);
				toast.success(response.message);

			} else if (response.status == false) {
				console.log('inside', response);
				toast.error(response.message);
			}
		}
	});
	return (
		<>
			<Header />





			<section className="heroBnnerImgNew" style={{ backgroundImage: "url('/website/assets/img/newimg/newbannerthirdroc.png')" }}>
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							{/* <h6 className="tWhiteSmall"><i class="las la-check-circle"></i> Most trusted Lending platform </h6> */}
							<h1 className="whiteBigHeadTxt">Autonomous Digital Lending Solution using AI Agents </h1>
							{/* <h4 className="middleWhiteSubTxt">For Lenders & Enterprises</h4> */}

							<div className="btnFlexNewHero">
								<Link to='/digital-lending' className="newBtnHeroImg">For Lenders <i class="las la-arrow-right"></i></Link>
								<Link to='/ewa' className="newBtnHeroImg">For Enterprises <i class="las la-arrow-right"></i></Link>
								<Link to='/bnpl' className="newBtnHeroImg">For Borrowers <i class="las la-arrow-right"></i></Link>
							</div>

						</div>
					</div>
				</div>
			</section>



			<section className="whyChooseeUsNew" style={{ backgroundImage: "url('/website/assets/img/newimg/whyusbg.png')" }}>
				<div className="container">
					<div className="row align-item-enter justify-content-center">
						<div className="col-md-10">
							<h2 className="whyChooeNewHead"> Why Choose Us</h2>
							<p className="whyChoosePraNew">Empower your B2B growth with cutting-edge credit risk solutions, seamless financing, and a trusted network of lenders and merchants—driven by innovation, precision, and flexibility.</p>
						</div>
					</div>

					<div className="row align-item-enter justify-content-center mt-4">
						<div className="col-md-3">
							<div className="whyChooseBorD">
								<h4><img src="website/assets/img/newimg/Bank.svg" alt="" /> Universal Access</h4>
								<p>Meant for Lenders, Enterprises & direct borrowers alike</p>
							</div>
						</div>
						<div className="col-md-3">
							<div className="whyChooseBorD">
								<h4><img src="website/assets/img/newimg/ix_ai.svg" alt="" /> AI Speed</h4>
								<p>AI Agents acting as loan processor & executing end-to-end loans in less than a minute</p>
							</div>
						</div>

						<div className="col-md-3">
							<div className="whyChooseBorD">
								<h4><img src="website/assets/img/newimg/icon-park-solid_api.svg" alt="" /> Multi-Platform</h4>
								<p>Web, mobile & API based solution</p>
							</div>
						</div>

						<div className="col-md-3">
							<div className="whyChooseBorD">
								<h4><img src="website/assets/img/newimg/ant-design_setting-filled.svg" alt="" /> Instant Start</h4>
								<p>No setup, no integrations needed. Instantly ready-to-go solution</p>
							</div>
						</div>

						<div className="col-md-3">
							<div className="whyChooseBorD">
								<h4><img src="website/assets/img/newimg/teenyicons_360-solid.svg" alt="" /> Full Profiling</h4>
								<p>360 degree profiling of borrower</p>
							</div>
						</div>

						<div className="col-md-3">
							<div className="whyChooseBorD">
								<h4><img src="website/assets/img/newimg/mdi_ocr.svg" alt="" /> Smart Extraction</h4>
								<p>Auto text extraction & analysis from bank statements & other documents</p>
							</div>
						</div>

						<div className="col-md-3">
							<div className="whyChooseBorD">
								<h4><img src="website/assets/img/newimg/file-signature.svg" alt="" /> Paperless Flow</h4>
								<p>Fully paperless loan processing, including digital signatures</p>
							</div>
						</div>

						<div className="col-md-3">
							<div className="whyChooseBorD">
								<h4><img src="website/assets/img/newimg/credit-score.svg" alt="" /> Credit Score</h4>
								<p>Alternative credit score using deep learning models</p>
							</div>
						</div>

						<div className="col-md-4">
							<div className="whyChooseBorD">
								<h4><img src="website/assets/img/newimg/mdi_book-secure.svg" alt="" /> Fast Insights</h4>
								<p>Super fast execution, continuous learning, finding hidden correlations, transparency & security in loan processing</p>
							</div>
						</div>
					</div>


				</div>
			</section>



			<section className="workNewWithUs">
				<div className="container">
					<div className="row align-item-enter justify-content-center">
						<div className="col-md-8">
							<h2 className="workWithUsNewHead">We work with</h2>
							<p className="workWithUsNewPra">Collaborating with industry leaders to deliver innovative solutions and drive mutual success.</p>
						</div>
					</div>

					<div className="row align-item-enter justify-content-center mt-4">
						<div className="col-md-2">
							<div className="workLogoWithUs">
								<img src="website/assets/img/newimg/c1.png" alt="" />
							</div>
						</div>
						<div className="col-md-2">
							<div className="workLogoWithUs">
								<img src="website/assets/img/newimg/c2.png" alt="" />
							</div>
						</div>
						{/* <div className="col-md-2">
				<div className="workLogoWithUs">
				    <img src="website/assets/img/newimg/c3.png" alt=""/>
				</div>
			</div> */}
						<div className="col-md-2">
							<div className="workLogoWithUs">
								<img src="website/assets/img/newimg/c4.png" alt="" />
							</div>
						</div>
						<div className="col-md-2">
							<div className="workLogoWithUs">
								<img src="website/assets/img/newimg/c5.png" alt="" />
							</div>
						</div>
					</div>

					<div className="row align-item-enter justify-content-center mt-2">
						<div className="col-md-2">
							<div className="workLogoWithUs">
								<img src="website/assets/img/newimg/c6.png" alt="" />
							</div>
						</div>
						<div className="col-md-2">
							<div className="workLogoWithUs">
								<img src="website/assets/img/newimg/c7.png" alt="" />
							</div>
						</div>
						{/* <div className="col-md-2">
							<div className="workLogoWithUs">
								<img src="website/assets/img/newimg/c8.png" alt="" />
							</div>
						</div> */}
						<div className="col-md-2">
							<div className="workLogoWithUs">
								<img src="website/assets/img/newimg/c9.png" alt="" />
							</div>
						</div>
						<div className="col-md-2">
							<div className="workLogoWithUs">
								<img src="website/assets/img/newimg/c10.png" alt="" />
							</div>
						</div>
					</div>

					<div className="row align-item-enter justify-content-center mt-3">
						<div className="col-md-12">
							<a href="" className="joinUsBtnNew">Join Us</a>
						</div>
					</div>




				</div>
			</section>






			{/*  Merchant Modal Start */}
			<div className="modal fade" id="modal-center1" tabIndex="-1" role="dialog" aria-labelledby="modal-centerLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="modal-centerLabel">Talk to us</h5>
							<button ref={ref2} type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<form onSubmit={form.handleSubmit}>
							{form.values.wrong_message ?
								<div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{form.values.wrong_message}</div>
								: ''}
							<div className="modal-body">
								<div className="formbox row">
									<div className="form-group form-group-mtb col-md-6">
										<input type="text"  {...form.getFieldProps("name")} placeholder="Enter Your Name" className="form-control" />
										{form.touched.name && form.errors.name ?
											<div className="invalid-feedback" style={{ display: "block" }}>{form.errors.name}</div> : ''}
									</div>
									<div className="form-group form-group-mtb col-md-6">
										<input type="text" {...form.getFieldProps("email")} placeholder="Enter Email ID" className="form-control" />
										{form.touched.email && form.errors.email ?
											<div className="invalid-feedback" style={{ display: "block" }}>{form.errors.email}</div> : ''}
									</div>
									<div className="form-group form-group-mtb col-md-12">
										<input type="number" name="mobile_number" {...form.getFieldProps("mobile_number")} placeholder="Enter Phone Number" className="form-control" />

										{form.touched.mobile_number && form.errors.mobile_number ?
											<div className="invalid-feedback" style={{ display: "block" }}>{form.errors.mobile_number}</div> : ''}
									</div>
									<div className="form-group form-group-mtb col-md-12">
										<input type="text" name="comment" {...form.getFieldProps("comment")} placeholder="Enter Comment" className="form-control" />

										{form.touched.comment && form.errors.comment ?
											<div className="invalid-feedback" style={{ display: "block" }}>{form.errors.comment}</div> : ''}
									</div>
									<div className="col-md-12">
										<button type="submit" className="btn btn-primary"> Send</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										<button className="btn btn-primary" data-dismiss="modal"> Close</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			{/*  Merchant Modal End */}

			{/*  Employer Modal Start */}
			<div className="modal fade" id="modal-center" tabIndex="-1" role="dialog" aria-labelledby="modal-centerLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered" role="document">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="modal-centerLabel">Talk to us</h5>
							<button ref={ref} type="button" className="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<form onSubmit={form.handleSubmit}>
							{form.values.wrong_message ?
								<div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{form.values.wrong_message}</div>
								: ''}
							<div className="modal-body">
								<div className="formbox row">
									<div className="form-group form-group-mtb col-md-6">
										<input type="text"  {...form.getFieldProps("name")} placeholder="Enter Your Name" className="form-control" />
										{form.touched.name && form.errors.name ?
											<div className="invalid-feedback" style={{ display: "block" }}>{form.errors.name}</div> : ''}
									</div>
									<div className="form-group form-group-mtb col-md-6">
										<input type="text" {...form.getFieldProps("email")} placeholder="Enter Email ID" className="form-control" />
										{form.touched.email && form.errors.email ?
											<div className="invalid-feedback" style={{ display: "block" }}>{form.errors.email}</div> : ''}
									</div>
									<div className="form-group form-group-mtb col-md-12">
										<input type="number" name="mobile_number" {...form.getFieldProps("mobile_number")} placeholder="Enter Phone Number" className="form-control" />

										{form.touched.mobile_number && form.errors.mobile_number ?
											<div className="invalid-feedback" style={{ display: "block" }}>{form.errors.mobile_number}</div> : ''}
									</div>
									<div className="form-group form-group-mtb col-md-12">
										<input type="text" name="comment" {...form.getFieldProps("comment")} placeholder="Enter Comment" className="form-control" />

										{form.touched.comment && form.errors.comment ?
											<div className="invalid-feedback" style={{ display: "block" }}>{form.errors.comment}</div> : ''}
									</div>
									<div className="col-md-12">
										<button type="submit" className="btn btn-primary"> Send</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										<button className="btn btn-primary" data-dismiss="modal"> Close</button>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
			{/*  Employer Modal End */}


			<Footer />
		</>
	)
}

export default Index;