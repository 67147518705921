import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Service from '../../adminService';
import { UseAppContext } from '../../Context/AppContext';
import images from "../../assets/img/1024-size.png"

// Bootstrap CSS
// const api = new Service();
// Bootstrap CSS
// import {sideMenuSet} from '../../assets/js/sidemenu';
export const Header = (props) => {
   const [adminData, setAdminData] = useState(localStorage.getItem("admin"));
   const [sub_permission, setSub_permission] = useState(JSON.parse(adminData)?.sub_permission);
   // const [main_permission, setMainPermission] = useState(JSON.parse(adminData)?.main_permission);
   // const [userType, setUserType] = useState(JSON.parse(adminData)?.user_type);
   const [userId, setUserId] = useState(JSON.parse(adminData)?.user_id);
   // const [allMainPermissions, setAllMainPermissions] = useState([]);
   // const [userSideMenu, setUserSideMenu] = useState([]);

   const [dashMenu, setDashMenu] = useState(false);
   const [partnerMenu, setPartnerMenu] = useState(false);
   const [lenderMenu, setLenderMenu] = useState(false);
   const [caseMenu, setCaseMenu] = useState(false);
   // const [queryBuilderMenu, setQueryBuilderMenu] = useState(false);
   const [profileMenu, setProfileMenu] = useState(false);
   const [logMenu, setLogMenu] = useState(false);
   const [notificationMenu, setNotificationMenu] = useState(false);
   // const [followUpMenu, setFollowUpMenu] = useState(false);
   const [settingMenu, setSettingMenu] = useState(false);
   // const [caseDetailMenu, setCaseDetailsMenu] = useState(false);
   const [BNPLSubmenu, setBNPLSubmenu] = useState(false);
   const [EarningSubmenu, setEarningSubmenu] = useState(false);
   const [EWAadvaceSubmenu, setEWAadvanceSubmenu] = useState(false);
   const [settinngSubmenu, setSettingSubmenu] = useState(false)
   const [settinngSubmenus, setSettingSubmenus] = useState(false)
   const [walletSubmenus, setWalletSubmenus] = useState(false)
   const [Subinvestment, setSubinvestment] = useState(false)
   const [SubEWAplan, setSubEWAplan] = useState(false)
   const navigate = useNavigate();
   const [subEWA, setSubEWA] = useState(false)
   const [subWFM, setSubWFM] = useState(false)
   const { header, ToggleHeader, Headeradminlogo } = UseAppContext();
   // const [location, setLocation] = useState(props.locationName)
   const [imageUrl, setImageUrl] = useState('');
   const [LogsSubmenu, setLogsSubmenu] = useState(false)

   useEffect(() => {

      // console.log('userId admin', userId);

   }, [userId])

   const showSubMenu = async () => {
      var dropdown = document.getElementsByClassName("dropdown-container");
      let ele = [...dropdown]

      if (ele[0].style.display == "none") {
         dropdown[0].style.display = "block"
         setSettingSubmenu(true)
      } else {
         dropdown[0].style.display = "none"
         setSettingSubmenu(false)
      }
   }
   const showSubMenuBNPL = async () => {
      var dropdown = document.getElementsByClassName("dropdown-contain");
      let ele = [...dropdown]

      if (ele[0].style.display == "none") {
         dropdown[0].style.display = "block"
         setBNPLSubmenu(true)
      } else {
         dropdown[0].style.display = "none"
         setBNPLSubmenu(false)
      }
   }
   const showSubMenuEarning = async () => {
      var dropdown = document.getElementsByClassName("dropdown-containEarning");
      let ele = [...dropdown]

      if (ele[0].style.display == "none") {
         dropdown[0].style.display = "block"
         setEarningSubmenu(true)
      } else {
         dropdown[0].style.display = "none"
         setEarningSubmenu(false)
      }
   }

   const showSubMenuEWAadvance = async () => {
      var dropdown = document.getElementsByClassName("dropdown-containEWAadvace");
      let ele = [...dropdown]

      if (ele[0].style.display == "none") {
         dropdown[0].style.display = "block"
         setEWAadvanceSubmenu(true)
      } else {
         dropdown[0].style.display = "none"
         setEWAadvanceSubmenu(false)

      }
   }

   const [manage, setManage] = useState(false)

   const Manage = async () => {
      var dropdown = document.getElementsByClassName("dropdown-manage");
      let ele = [...dropdown]

      if (ele[0].style.display == "none") {
         dropdown[0].style.display = "block"
         setManage(true)
      } else {
         dropdown[0].style.display = "none"
         setManage(false)
      }
   }

   const showSubMenuLogs = async () => {
      var dropdown = document.getElementsByClassName("dropdown-containlogs");
      let ele = [...dropdown]

      if (ele[0].style.display == "none") {
         dropdown[0].style.display = "block"
         setLogsSubmenu(true)
      } else {
         dropdown[0].style.display = "none"
         setLogsSubmenu(false)
      }
   }

   const showSubMenus = async () => {
      var dropdown = document.getElementsByClassName("dropdown-containers");
      let ele = [...dropdown]

      if (ele[0].style.display == "none") {
         dropdown[0].style.display = "block"
         setSettingSubmenus(true)
      } else {
         dropdown[0].style.display = "none"
         setSettingSubmenus(false)
      }
   }

   const showSubMenusWallet = async () => {
      var dropdown = document.getElementsByClassName("dropdown-containerswallet");
      let ele = [...dropdown]

      if (ele[0].style.display == "none") {
         dropdown[0].style.display = "block"
         setWalletSubmenus(true)
      } else {
         dropdown[0].style.display = "none"
         setWalletSubmenus(false)
      }
   }

   const showSubMenusinvestment = async () => {
      var dropdown = document.getElementsByClassName("dropdown-containersinvestment");
      let ele = [...dropdown]

      if (ele[0].style.display == "none") {
         dropdown[0].style.display = "block"
         setSubinvestment(true)
      } else {
         dropdown[0].style.display = "none"
         setSubinvestment(false)
      }
   }



   const HandleSubmenusForEWA = async () => {
      var dropdown = document.getElementsByClassName("dropdown-containersEWA");
      let ele = [...dropdown]

      if (ele[0].style.display == "none") {
         dropdown[0].style.display = "block"
         setSubEWA(true)
      } else {
         dropdown[0].style.display = "none"
         setSubEWA(false)
      }
   }

   const HandleSubmenusForWFM = async () => {
      var dropdown = document.getElementsByClassName("dropdown-containersWFM");
      let ele = [...dropdown]

      if (ele[0].style.display == "none") {
         dropdown[0].style.display = "block"
         setSubWFM(true)
      } else {
         dropdown[0].style.display = "none"
         setSubWFM(false)
      }
   }

   const showEWAplan = async () => {
      var dropdown = document.getElementsByClassName("dropdown-containersEWAplan");
      let ele = [...dropdown]

      if (ele[0].style.display == "none") {
         dropdown[0].style.display = "block"
         setSubEWAplan(true)
      } else {
         dropdown[0].style.display = "none"
         setSubEWAplan(false)
      }
   }

   const logOut = () => {
      localStorage.removeItem("admin");
      navigate('/admin/login')
   }

   useEffect(() => {
      const storedImageUrl = localStorage.getItem("profileImageUrl")
      if (storedImageUrl) {
         setImageUrl(storedImageUrl);
      }
   }, [imageUrl, Headeradminlogo])
   // console.log(Headeradminlogo)



   return (
      <>
         <div className={header ? "app-header header sticky app_header_res" : "app-header header sticky"}>
            <div className="container-fluid main-container">
               <div className="d-flex">
                  <div className="app-sidebar__toggle d-flex align-items-center" data-bs-toggle="sidebar">
                     <Link className="open-toggle" to="" onClick={() => ToggleHeader()}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="feather feather-align-left header-icon" width="24" height="24" viewBox="0 0 24 24">
                           <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z"></path>
                        </svg>
                     </Link>
                  </div>
                  <Link className="logo-horizontal " to={props.prefix}>
                     {/* <img src={imageUrl || images} className="header-brand-img desktop-lgo" alt="logo" /> */}
                     <img src={process.env.PUBLIC_URL + "/assets/img/logo-thirdroc.png"} className="header-brand-img desktop-lgo" alt="logo" />
                     <img src={process.env.PUBLIC_URL + "/assets/img/logo-thirdroc.png"} className="header-brand-img dark-logo" alt="logo" />
                  </Link>
                  <div className="d-flex order-lg-2 ms-auto main-header-end">
                     <div className="navbar navbar-expand-lg navbar-collapse responsive-navbar p-0">
                        <div className="collapse navbar-collapse" id="navbarSupportedContent-4"> <div className="d-flex order-lg-2">
                           {/* <div className="dropdown header-notify d-flex">
                              <Link className="nav-link icon" data-bs-toggle="dropdown"> <svg xmlns="http://www.w3.org/2000/svg" className="header-icon" width="24" height="24" viewBox="0 0 24 24"> <path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z"> </path> </svg>
                                 <span className="badge bg-orange side-badge">5</span> </Link>
                           </div> */}
                           <div className="dropdown profile-dropdown d-flex">
                              <Link to={props.prefix + '/adminprofie'} className="nav-link pe-0 leading-none" data-bs-toggle="dropdown">
                                 <span className="header-avatar1"> <img src={imageUrl || images} alt="img" className="avatar avatar-md brround" /> </span> </Link>
                              <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow animated">
                                 {/* <div className="text-left"><div className="text-left user font-weight-bold ps-sm-4">SMT Lab </div> 
				        <div className="dropdown-divider"></div> 
				      </div>  */}
                                 <li className="slide">
                                    <Link to={props.prefix + "/adminprofile"} className="dropdown-item d-flex" >
                                       <div className="fs-13"> <i className="fa-solid fa-user fa-fw header-icon me-2"></i> Profile</div>
                                    </Link>
                                 </li>
                                 <li className="slide">
                                    <Link to={props.prefix + "/changepassword"} className="dropdown-item d-flex">
                                       <div className="fs-13"> <i className="fa-solid fa-key fa-fw header-icon me-2"></i>Change Password</div>
                                    </Link>
                                 </li>
                                 {/* <li className="slide">
                                    <Link to={props.prefix + "/privacypolicy"} className="dropdown-item d-flex">
                                       <div className="fs-13"> <i className="fas fa-user-lock fa-fw header-icon me-2"></i>Privacy Policy</div>
                                    </Link>
                                 </li> */}
                                 <Link className="dropdown-item d-flex" to="/admin/login" onClick={(e) => logOut(e)}>
                                    <svg className="header-icon me-2" xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24"> <g> <rect fill="none" height="24" width="24"></rect> </g> <g> <path d="M11,7L9.6,8.4l2.6,2.6H2v2h10.2l-2.6,2.6L11,17l5-5L11,7z M20,19h-8v2h8c1.1,0,2-0.9,2-2V5c0-1.1-0.9-2-2-2h-8v2h8V19z"> </path> </g> </svg> <div className="fs-13">Sign Out</div>
                                 </Link> </div>
                           </div>
                        </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="sticky is-expanded  ">
            <div className="app-sidebar__overlay active" data-bs-toggle="sidebar"></div>
            {/* <aside  className="app-sidebar  open ps ps--active-y " style={{overflowY : 'scroll'}}> */}
            <aside className={header ? "app-sidebar open ps ps--active-y res_aside" : "app-sidebar open ps ps--active-y"}  >
               <div className={header ? "app-sidebar__logo res_aside" : "app-sidebar__logo"}>
                  <Link className="header-brand" /* to={props.prefix} */ style={{ cursor: 'none' }}>
                     <img src={process.env.PUBLIC_URL + "/assets/img/logo-thirdroc.png"} className="header-brand-img desktop-lgo" alt="logo" />
                     <img src={process.env.PUBLIC_URL + "/assets/img/logo-thirdroc.png"} className="header-brand-img mobile-logo" alt="Azea logo" />
                  </Link>
               </div>
               <div className="main-sidemenu is-expanded">
                  <div className="slide-left disabled active is-expanded" id="slide-left">
                     <svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"></path>
                     </svg>
                  </div>
                  {/* {userType == 'SUPER-ADMIN' || userType == 'admin' ? */}
                  {userId == '1' ?
                     <ul className="side-menu app-sidebar3 open" style={{ marginRight: "0px" }}>
                        {/* <li className="side-item side-item-category">Main</li> */}
                        <li className="slide is-expanded">
                           <Link className={props.locationName == (props.prefix + '/dashboard' || props.prefix) ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/dashboard'}>
                              <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                                 <path d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586 6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z"></path>
                              </svg>
                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Dashboard</span>
                           </Link>
                        </li>

                        {/* <li className="slide is-expanded">
                           <Link className={props.locationName == props.prefix + '/dsa' ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/dsa'}>
                              <svg xmlns="http://www.w3.org/2000/svg" className={header?"side-menu__icon res_icon":"side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                                 <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                              </svg>
                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Partner Report</span>
                           </Link>
                        </li> */}
                        {/* <li className="slide is-expanded">
                           <Link className={props.locationName == props.prefix + '/loanPoint' ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/loanPoint'}>
                              <svg xmlns="http://www.w3.org/2000/svg" className={header?"side-menu__icon res_icon":"side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                                 <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                              </svg>
                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Loan Point Report</span>
                           </Link>
                        </li>
                        <li className="slide is-expanded">
                           <Link className={props.locationName == props.prefix + '/users' ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/users'}>
                              <svg xmlns="http://www.w3.org/2000/svg" className={header?"side-menu__icon res_icon":"side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                                 <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                              </svg>
                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>User Report</span>
                           </Link>
                        </li>  */}
                        <li className="slide is-expanded">
                           <Link className={props.locationName == props.prefix + '/borrower' ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/borrower'}>
                              <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                                 <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                              </svg>
                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Borrowers</span>
                           </Link>
                        </li>
                        {/* <li className="slide is-expanded">
                           <Link className={props.locationName == props.prefix + '/ewa_advance_application' ? "side-menu__item active" : "side-menu__item"}
                              to={props.prefix + '/ewa_advance_application'}>
                              <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                                 <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                              </svg>
                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>EWA Advance <br />Application</span>
                           </Link>
                        </li>  */}
                        <li className="slide is-expanded">
                           <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                              <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                           </svg>
                           {/* <i className={header ? "fas fa-money-check-alt side-menu__icon fs-18 res_icon" : "fas fa-money-check-alt side-menu__icon fs-18"}></i> */}
                           <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { showSubMenuEWAadvance() }}
                              style={{ marginLeft: 1, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>EWA Advace
                              <i className={EWAadvaceSubmenu ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                           <div className="dropdown-containEWAadvace" style={{ display: "none", marginLeft: 40, paddingLeft: 8 }}>
                              <Link className={props.locationName == props.prefix + '/ewa_advance_application' ? "side-menu__item active" : "side-menu__item"}
                                 to={props.prefix + '/ewa_advance_application'}> <div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Application</div></Link>

                              <Link className={props.locationName == props.prefix + '/view_reconciliation' ? "side-menu__item active" : "side-menu__item"}
                                 to={props.prefix + '/view_reconciliation'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Reconciliation</div></Link>

                              <Link className={props.locationName == props.prefix + '/reminder' ? "side-menu__item active" : "side-menu__item"}
                                 to={props.prefix + '/reminder'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Reminder</div></Link>

                              <Link className={props.locationName == props.prefix + '/failed_reminder_logs' ? "side-menu__item active" : "side-menu__item"}
                                 to={props.prefix + '/failed_reminder_logs'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Failed Reminder Logs</div></Link>


                           </div>
                        </li>
                        {/* <li className="slide is-expanded">
                           <Link className={props.locationName == props.prefix + '/view_reconciliation' ? "side-menu__item active" : "side-menu__item"}
                              to={props.prefix + '/view_reconciliation'}>
                              <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                                 <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                              </svg>
                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Reconciliation</span>
                           </Link>
                        </li> */}
                        <li className="slide is-expanded">
                           <Link className={props.locationName == props.prefix + '/employer' ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/employer'}>
                              <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                                 <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                              </svg>
                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Employers </span>
                           </Link>
                        </li>
                        <li className="slide is-expanded">
                           <Link className={props.locationName == props.prefix + '/lender' ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/lender'}>
                              <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                                 <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                              </svg>
                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Lenders </span>
                           </Link>
                        </li>
                        <li className="slide is-expanded">
                           <Link className={props.locationName == props.prefix + '/merchant' ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/merchant'}>
                              <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                                 <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                              </svg>
                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Merchants </span>
                           </Link>
                        </li>
                        {/* <li className="slide is-expanded">
                           <Link className={props.locationName == props.prefix + '/EmployeeLogs' ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/EmployeeLogs'}>
                              <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                                 <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                              </svg>
                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Employee Logs</span>
                           </Link>
                        </li> */}

                        {/* <li className="slide is-expanded">
                           <Link className={props.locationName == props.prefix + '/settlement' ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/settlement'}>
                              <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                                 <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                              </svg>
                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Settlement Report</span>
                           </Link>
                        </li> */}
                        {/* <li className="slide is-expanded">
                           <Link className={props.locationName == props.prefix + '/pendingSettlement' ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/pendingSettlement'}>
                              <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                                 <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                              </svg>
                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Pending Settlement</span>
                           </Link>
                        </li> */}
                        <li className="slide is-expanded">
                           <Link className={props.locationName == props.prefix + '/directusers' ? "side-menu__item active" : "side-menu__item"}
                              to={props.prefix + '/directusers'}>
                              <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                                 <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                              </svg>
                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Direct Borrowers</span>
                           </Link>
                        </li>
                        <li className="slide is-expanded">
                           <Link className={props.locationName == props.prefix + '/lenderinterest' ? "side-menu__item active" : "side-menu__item"}
                              to={props.prefix + '/lenderinterest'}>
                              <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                                 <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                              </svg>
                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Lender Interest</span>
                           </Link>
                        </li>
                        <li className="slide is-expanded">
                           <Link className={props.locationName == props.prefix + '/employee_transaction' ? "side-menu__item active" : "side-menu__item"}
                              to={props.prefix + '/employee_transaction'}>
                              <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                                 <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                              </svg>
                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>All Employee TXN</span>
                           </Link>
                        </li>
                        <li className="slide is-expanded">
                           <Link className={props.locationName == props.prefix + '/manage_promotion' ? "side-menu__item active" : "side-menu__item"}
                              to={props.prefix + '/manage_promotion'}>
                              {/* <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                                 <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                              </svg> */}
                              <i className={header ? "fas fa-box side-menu__icon fs-18 res_icon" : "fas fa-box side-menu__icon fs-18"}></i>
                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Manage Promotion</span>
                           </Link>
                        </li>
                        <li className="slide is-expanded">
                           <Link className={props.locationName == props.prefix + '/cashback' ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/cashback'}>
                              {/* <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                                 <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                              </svg> */}
                              <i className={header ? "fas fa-cash-register side-menu__icon fs-18 res_icon" : "fas fa-cash-register side-menu__icon fs-18"}></i>

                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Cash Back</span>
                           </Link>
                        </li>
                        <li className="slide is-expanded">
                           <Link className={props.locationName == props.prefix + '/transfer_request' ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/transfer_request'}>
                              {/* <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                                 <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                              </svg> */}
                              <i className={header ? "fas fa-cash-register side-menu__icon fs-18 res_icon" : "fas fa-cash-register side-menu__icon fs-18"}></i>

                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Transfer Request</span>
                           </Link>
                        </li>
                        {/* <li className="slide is-expanded">
                           <Link className={props.locationName == props.prefix + '/banktransfer' ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/banktransfer'}>
                              <i className={header ? "fas fa-location-arrow side-menu__icon fs-18 res_icon" : "fas fa-location-arrow side-menu__icon fs-18"}></i>
                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Bank Transfer</span>
                           </Link>
                        </li> */}
                        <li className="slide is-expanded">
                           {/* <Link className={props.locationName == props.prefix + '/planlist' ? "side-menu__item active" : "side-menu__item"} 
                           to={props.prefix + '/planlist'}> */}
                           {/* <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                              <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                           </svg> */}
                           <i className={header ? "fas fa-money-check-alt side-menu__icon fs-18 res_icon" : "fas fa-money-check-alt side-menu__icon fs-18"}></i>

                           {/* <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Plan Setting</span> */}
                           <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { showSubMenuEarning() }}
                              style={{ marginLeft: 1, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>Earning
                              <i className={EarningSubmenu ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                           <div className="dropdown-containEarning" style={{ display: "none", marginLeft: 40, paddingLeft: 8 }}>
                              <Link className={props.locationName == props.prefix + '/bnpl_earning' ? "side-menu__item active" : "side-menu__item"}
                                 to={props.prefix + '/bnpl_earning'}   > <div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> BNPL Earning</div></Link>

                              <Link className={props.locationName == props.prefix + '/ewa_earning' ? "side-menu__item active" : "side-menu__item"}
                                 to={props.prefix + '/ewa_earning'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> EWA Earning</div></Link>


                           </div>
                        </li>

                        <li className="slide is-expanded">

                           <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                              <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                           </svg>
                           <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { Manage() }}
                              style={{ marginLeft: 1, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>Manage
                              <i className={manage ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                           <div className="dropdown-manage" style={{ display: "none", marginLeft: 40, paddingLeft: 8 }}>

                              {/* Manage BNPL Sidebar Start */}

                              <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { showSubMenuBNPL() }}
                                 style={{ marginLeft: 4, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>BNPL
                                 <i className={BNPLSubmenu ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                              <br /> <div className="dropdown-contain" style={{ display: "none", marginLeft: 8, paddingLeft: 8 }}>
                                 <Link className={props.locationName == props.prefix + '/bnpl_transaction' ? "side-menu__item active" : "side-menu__item"}
                                    to={props.prefix + '/bnpl_transaction'}   > <div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Pending</div></Link>

                                 <Link className={props.locationName == props.prefix + '/bnpl_settlement' ? "side-menu__item active" : "side-menu__item"}
                                    to={props.prefix + '/bnpl_settlement'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Paid</div></Link>


                              </div>

                              {/* Manage BNPL Sidebar End */}

                              {/* Manage EWA Sidebar Start */}
                              <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { showSubMenus() }}
                                 style={{ marginLeft: 4, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>EWA
                                 <i className={settinngSubmenus ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                              <br />  <div className="dropdown-containers" style={{ display: "none", marginLeft: 8, paddingLeft: 8 }}>


                                 <Link className={props.locationName == props.prefix + '/merchantdues' ? "side-menu__item active" : "side-menu__item"}
                                    to={props.prefix + '/merchantdues'}   > <div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Pending</div></Link>

                                 <Link className={props.locationName == props.prefix + '/merchantpaid' ? "side-menu__item active" : "side-menu__item"}
                                    to={props.prefix + '/merchantpaid'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Paid</div></Link>
                                 {/* EWA Plan Sidebar Start */}

                                 <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { HandleSubmenusForEWA() }}
                                    style={{ marginLeft: 1, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}> Plan
                                    <i className={subEWA ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                                 <div className="dropdown-containersEWA" style={{ display: "none", marginLeft: 4, paddingLeft: 0 }}>
                                    <Link className={props.locationName == props.prefix + '/ewaplateformcharges' ? "side-menu__item active" : "side-menu__item"}
                                       to={props.prefix + '/ewaplateformcharges'}   > <div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Employer EWA</div></Link>
                                    <Link className={props.locationName == props.prefix + '/viewplan' ? "side-menu__item active" : "side-menu__item"}
                                       to={props.prefix + '/viewplan'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}>EWA Plan List</div></Link>
                                    <Link className={props.locationName == props.prefix + '/monthly_ewa_deduction' ? "side-menu__item active" : "side-menu__item"}
                                       to={props.prefix + '/monthly_ewa_deduction'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}>Monthly Deduction</div></Link>
                                    <Link className={props.locationName == props.prefix + '/deduction_error_logs' ? "side-menu__item active" : "side-menu__item"}
                                       to={props.prefix + '/deduction_error_logs'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}>{/* Deduction */} Error Logs</div></Link>
                                 </div>

                                 {/* EWA Plan Sidebar End */}
                              </div>

                              {/* Manage EWA Sidebar End */}


                              {/* Manage Wallet Sidebar Start */}

                              <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { showSubMenusWallet() }}
                                 style={{ marginLeft: 4, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>Wallet
                                 <i className={walletSubmenus ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                              <br /><div className="dropdown-containerswallet" style={{ display: "none", marginLeft: 8, paddingLeft: 8 }}>
                                 <Link className={props.locationName == props.prefix + '/wallet_view_pending' ? "side-menu__item active" : "side-menu__item"}
                                    to={props.prefix + '/wallet_view_pending'}   > <div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Pending</div></Link>

                                 <Link className={props.locationName == props.prefix + '/wallet_view_paid' ? "side-menu__item active" : "side-menu__item"}
                                    to={props.prefix + '/wallet_view_paid'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Paid</div></Link>

                              </div>

                              {/* Manage Wallet Sidebar End */}

                              {/* WFM Sidebar Start */}

                              <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { showEWAplan() }}
                                 style={{ marginLeft: 4, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>WFM
                                 <i className={SubEWAplan ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                              <br />  <div className="dropdown-containersEWAplan" style={{ display: "none", marginLeft: 8, paddingLeft: 8 }}>
                                 <Link className={props.locationName == props.prefix + '/stripe_plan' ? "side-menu__item active" : "side-menu__item"}
                                    to={props.prefix + '/stripe_plan'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}>Stripe Plan </div></Link>
                                 <Link className={props.locationName == props.prefix + '/pf' ? "side-menu__item active" : "side-menu__item"}
                                    to={props.prefix + '/pf'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}>PF Plan</div></Link>
                                 <Link className={props.locationName == props.prefix + '/taxslab' ? "side-menu__item active" : "side-menu__item"}
                                    to={props.prefix + '/taxslab'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}>Tax Slab</div></Link>

                              </div>

                              {/* WFM Sidebar End */}

                              {/* Manage Investment Sidebar Start */}

                              <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { showSubMenusinvestment() }}
                                 style={{ marginLeft: 4, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>Investment
                                 <i className={Subinvestment ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                              <div className="dropdown-containersinvestment" style={{ display: "none", marginLeft: 8, paddingLeft: 8 }}>
                                 <Link className={props.locationName == props.prefix + '/view_investment' ? "side-menu__item active" : "side-menu__item"}
                                    to={props.prefix + '/view_investment'}   > <div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> View Investment</div></Link>

                              </div>

                              {/* Manage Investment Sidebar End */}


                           </div>

                        </li>

                        {/* <li className="slide is-expanded">
                           <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                              <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                           </svg>
                           <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { showSubMenuBNPL() }}
                              style={{ marginLeft: 1, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>Manage BNPL
                              <i className={BNPLSubmenu ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                           <div className="dropdown-contain" style={{ display: "none", marginLeft: 40, paddingLeft: 8 }}>
                              <Link className={props.locationName == props.prefix + '/bnpl_transaction' ? "side-menu__item active" : "side-menu__item"}
                                 to={props.prefix + '/bnpl_transaction'}   > <div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Pending</div></Link>

                              <Link className={props.locationName == props.prefix + '/bnpl_settlement' ? "side-menu__item active" : "side-menu__item"}
                                 to={props.prefix + '/bnpl_settlement'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Paid</div></Link>


                           </div>
                         
                        </li> */}


                        {/* <li className="slide is-expanded">
                           <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                              <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                           </svg>

                           <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { showSubMenus() }}
                              style={{ marginLeft: 1, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>Manage EWA
                              <i className={settinngSubmenus ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                           <div className="dropdown-containers" style={{ display: "none", marginLeft: 40, paddingLeft: 8 }}>
                              <Link className={props.locationName == props.prefix + '/merchantdues' ? "side-menu__item active" : "side-menu__item"}
                                 to={props.prefix + '/merchantdues'}   > <div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Pending</div></Link>

                              <Link className={props.locationName == props.prefix + '/merchantpaid' ? "side-menu__item active" : "side-menu__item"}
                                 to={props.prefix + '/merchantpaid'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Paid</div></Link>
                              </div>
                        </li> */}


                        {/* <li className="slide is-expanded">
                           <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                              <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                           </svg>

                           <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { showSubMenusWallet() }}
                              style={{ marginLeft: 1, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>Manage Wallet
                              <i className={walletSubmenus ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                           <div className="dropdown-containerswallet" style={{ display: "none", marginLeft: 40, paddingLeft: 8 }}>
                              <Link className={props.locationName == props.prefix + '/wallet_view_pending' ? "side-menu__item active" : "side-menu__item"}
                                 to={props.prefix + '/wallet_view_pending'}   > <div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Pending</div></Link>

                              <Link className={props.locationName == props.prefix + '/wallet_view_paid' ? "side-menu__item active" : "side-menu__item"}
                                 to={props.prefix + '/wallet_view_paid'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Paid</div></Link>

                           </div>

                        </li> */}


                        {/* <li className="slide is-expanded">
                           <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                              <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                           </svg>

                           <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { showSubMenusinvestment() }}
                              style={{ marginLeft: 1, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>Manage Investment
                              <i className={Subinvestment ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                           <div className="dropdown-containersinvestment" style={{ display: "none", marginLeft: 40, paddingLeft: 8 }}>
                              <Link className={props.locationName == props.prefix + '/view_investment' ? "side-menu__item active" : "side-menu__item"}
                                 to={props.prefix + '/view_investment'}   > <div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> View Investment</div></Link>                            

                           </div>
                        </li> */}
                        {/* <li className="slide is-expanded">
                           <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                              <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                           </svg>
                           <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { showEWAplan() }}
                              style={{ marginLeft: 1, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>WFM
                              <i className={SubEWAplan ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                           <div className="dropdown-containersEWAplan" style={{ display: "none", marginLeft: 40, paddingLeft: 8 }}>
                              <Link className={props.locationName == props.prefix + '/plan_list' ? "side-menu__item active" : "side-menu__item"}
                                 to={props.prefix + '/plan_list'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}>Plan List</div></Link>
                              <Link className={props.locationName == props.prefix + '/payroll' ? "side-menu__item active" : "side-menu__item"}
                                 to={props.prefix + '/payroll'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}>Manage Payroll</div></Link>
                              <li className="slide is-expanded">
                               <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { HandleSubmenusForEWA() }}
                                    style={{ marginLeft: 4, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>EWA Plan
                                    <i className={subEWA ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                                 <div className="dropdown-containersEWA" style={{ display: "none", marginLeft: 8, paddingLeft: 8 }}>
                                    <Link className={props.locationName == props.prefix + '/ewaplateformcharges' ? "side-menu__item active" : "side-menu__item"}
                                       to={props.prefix + '/ewaplateformcharges'}   > <div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Employer EWA</div></Link>
                                    <Link className={props.locationName == props.prefix + '/viewplan' ? "side-menu__item active" : "side-menu__item"}
                                       to={props.prefix + '/viewplan'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}>EWA Plan List</div></Link>
                                    <Link className={props.locationName == props.prefix + '/monthly_ewa_deduction' ? "side-menu__item active" : "side-menu__item"}
                                       to={props.prefix + '/monthly_ewa_deduction'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}>Monthly Deduction</div></Link>
                                    <Link className={props.locationName == props.prefix + '/deduction_error_logs' ? "side-menu__item active" : "side-menu__item"}
                                       to={props.prefix + '/deduction_error_logs'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Error Logs</div></Link>
                                 </div> 
                              </li>

                              <li className="slide is-expanded">
                                 <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { HandleSubmenusForWFM() }}
                                    style={{ marginLeft: 4, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>WFM
                                    <i className={subWFM ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                                 <div className="dropdown-containersWFM" style={{ display: "none", marginLeft: 6, paddingLeft: 4 }}>
                                    <Link className={props.locationName == props.prefix + '/plan_list' ? "side-menu__item active" : "side-menu__item"}
                                       to={props.prefix + '/plan_list'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}>Plan List</div></Link>
                                    <Link className={props.locationName == props.prefix + '/payroll' ? "side-menu__item active" : "side-menu__item"}
                                       to={props.prefix + '/payroll'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}>Manage Payroll</div></Link>
                                 </div>
                              </li>
                           </div>
                        </li> */}


                        <li className="slide is-expanded">
                           <i className={header ? "fa-regular fa-hard-drive side-menu__icon fs-18 res_icon" : "fa-regular fa-hard-drive side-menu__icon fs-18"}></i>

                           {/* <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Plan Setting</span> */}
                           <span className={header ? "side-menu__label d-none " : "side-menu__label"} onClick={() => { showSubMenuLogs() }}
                              style={{ marginLeft: 1, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>Logs
                              <i className={LogsSubmenu ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                           <div className="dropdown-containlogs" style={{ display: "none", marginLeft: 40, paddingLeft: 8 }}>
                              <Link className={props.locationName == props.prefix + '/userloginlog' ? "side-menu__item active" : "side-menu__item"}
                                 to={props.prefix + '/userloginlog'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> User Logs</div></Link>
                              <Link className={props.locationName == props.prefix + '/request' ? "side-menu__item active" : "side-menu__item"}
                                 to={props.prefix + '/request'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Request Logs</div></Link>
                              <Link className={props.locationName == props.prefix + '/employeelogs' ? "side-menu__item active" : "side-menu__item"}
                                 to={props.prefix + '/employeelogs'}   > <div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Employee Logs</div></Link>
                              <Link className={props.locationName == props.prefix + '/attendance_error_logs' ? "side-menu__item active" : "side-menu__item"}
                                 to={props.prefix + '/attendance_error_logs'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Attendance Error Log</div></Link>
                              <Link className={props.locationName == props.prefix + '/ml_analysis_logs' ? "side-menu__item active" : "side-menu__item"}
                                 to={props.prefix + '/ml_analysis_logs'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> MLanalysis Error Log</div></Link>
                           </div>
                           {/* </Link> */}
                        </li>
                        <li className="slide is-expanded">
                           {/* <Link className={props.locationName == props.prefix + '/planlist' ? "side-menu__item active" : "side-menu__item"} 
                           to={props.prefix + '/planlist'}> */}
                           {/* <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                              <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                           </svg> */}
                           <i className={header ? "fa-solid fa-gear fa-fw side-menu__icon fs-18 res_icon" : "fa-solid fa-gear fa-fw side-menu__icon fs-18"}></i>
                           {/* <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Plan Setting</span> */}
                           <span className={header ? " d-none " : ""} onClick={() => { showSubMenu() }}
                              style={{ marginLeft: 1, fontWeight: 600, color: '#555b6d', fontSize: 13, cursor: 'pointer' }}>Setting
                              <i className={settinngSubmenu ? "fa fa-caret-down" : "fa fa-caret-right"} style={{ marginTop: 2, marginLeft: 8 }}></i></span>
                           <div className="dropdown-container" style={{ display: "none", marginLeft: 40, paddingLeft: 8 }}>
                              <Link className={props.locationName == props.prefix + '/planlist/country' ? "side-menu__item active" : "side-menu__item"}
                                 to={props.prefix + '/planlist/country'}   > <div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Country</div></Link>
                              <Link className={props.locationName == props.prefix + '/planlist/merchantcommission' ? "side-menu__item active" : "side-menu__item"}
                                 to={props.prefix + '/planlist/merchantcommission'}><div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Merchant Commission</div></Link>
                              <Link className={props.locationName == props.prefix + '/planlist/lendercommission' ? "side-menu__item active" : "side-menu__item"}
                                 to={props.prefix + '/planlist/lendercommission'}> <div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Lender ROI</div></Link><Link className={props.locationName == props.prefix + '/planlist/balance_transfer' ? "side-menu__item active" : "side-menu__item"}
                                    to={props.prefix + '/planlist/balance_transfer'}> <div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Balance transfer</div></Link>
                              <Link className={props.locationName == props.prefix + '/planlist/investor_setting' ? "side-menu__item active" : "side-menu__item"}
                                 to={props.prefix + '/planlist/investor_setting'}> <div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Investor Setting</div></Link>
                              <Link className={props.locationName == props.prefix + '/feedback' ? "side-menu__item active" : "side-menu__item"}
                                 to={props.prefix + '/feedback'}> <div className='side-menu__label' style={{ display: "block", cursor: 'pointer' }}> Feedback Detail</div></Link>

                           </div>
                           {/* </Link>/planlist/balance_transfer */}
                        </li>

                        <li className="slide is-expanded">
                           <Link className={props.locationName == props.prefix + '/talk_to_us' ? "side-menu__item active" : "side-menu__item"}
                              to={props.prefix + '/talk_to_us'}>
                              {/* <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                                 <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                              </svg> */}
                              <i className={header ? "fas fa-headset side-menu__icon fs-18 res_icon" : "fas fa-headset side-menu__icon fs-18"}></i>

                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Talk To Us</span>
                           </Link>
                        </li>
                        <li className="slide is-expanded">
                           <Link className={props.locationName == props.prefix + '/manage_sms' ? "side-menu__item active" : "side-menu__item"}
                              to={props.prefix + '/manage_sms'}>
                              <i className={header ? "fa-solid fa-comment-sms side-menu__icon fs-18 res_icon" : "fa-solid fa-comment-sms side-menu__icon fs-18"}></i>
                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Manage SMS</span>
                           </Link>
                        </li>

                        <li className="slide is-expanded">
                           <Link className={props.locationName == props.prefix + '/ewa_agree' ? "side-menu__item active" : "side-menu__item"}
                              to={props.prefix + '/ewa_agree'}>
                              <svg xmlns="http://www.w3.org/2000/svg" className={header ? "side-menu__icon res_icon" : "side-menu__icon"} width="24" height="24" viewBox="0 0 24 24">
                                 <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                              </svg>
                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>EWA Agree</span>
                           </Link>
                        </li>
                        <li className="slide is-expanded logout1" onClick={() => logOut()}>
                           <Link className="side-menu__item" to={props.prefix + '/'} >
                              <i className="fa-regular fas fa-sign-out fa-fw side-menu__icon fs-18"></i>
                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Log Out</span>
                           </Link>
                        </li>
                        {/* <li className="slide">
                     <Link to="" className="side-menu__item" data-bs-toggle="slide" >
                       <i className="fa-solid fa-database fa-fw side-menu__icon fs-18"></i>
                        <span className={header?"side-menu__label d-none":"side-menu__label"}>Report</span><i className="angle fa fa-chevron-right"></i>
                     </Link>
                     <ul className="slide-menu">
                        <li className="side-menu-label1"><Link to={props.prefix}>Report</Link></li>
                        <li><Link to={props.prefix} className="slide-item"> Cases Report</Link></li>
                        <li><Link to={props.prefix} className="slide-item"> Partner Wise Report</Link></li>
                        <li><Link to={props.prefix} className="slide-item"> Lender Wise Report</Link></li>
                        <li><Link to={props.prefix} className="slide-item"> Disbursed Report</Link></li>
                     </ul>
                  </li> */}
                        {/* <li className="slide is-expanded">
                     <Link className="side-menu__item" to={props.prefix+'/query-builder'}>
                        <i className="fa-regular fa-user fa-fw side-menu__icon fs-18"></i>
                        <span className={header?"side-menu__label d-none":"side-menu__label"}>Query Builder</span>
                     </Link>
                  </li> */}


                        {/* <li className="slide is-expanded">
                           <Link className={props.locationName == props.prefix + '/profile' || props.locationName == props.prefix + '/add-profile' || props.locationName == props.prefix + '/edit-profile/' + props.profile_id ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/profile'}>
                              <i className={header ? "res_icon fa-regular fa-address-card fa-fw side-menu__icon fs-18" : "fa-regular fa-address-card fa-fw side-menu__icon fs-18"}></i>
                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Profile</span>
                           </Link>
                        </li> */}


                        {/* <li className="slide is-expanded">
                           <Link className={props.locationName == props.prefix + '/user' || props.locationName == props.prefix + '/add-user' || props.locationName == props.prefix + '/edit-user/' + props.user_id ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/user'}>
                              <i className={header?"res_icon fa fa-users side-menu__icon fs-18":"fa fa-users side-menu__icon fs-18"}></i>
                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Users</span>
                           </Link>
                        </li> */}
                        {/* <li className="slide is-expanded">
                           <Link className={props.locationName == props.prefix + '/login-log' ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/login-log'}>
                              <svg xmlns="http://www.w3.org/2000/svg" className={header?"side-menu__icon res_icon":"side-menu__icon"} width="24" height="24" viewBox="0 0 24 24"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"> </path> </svg>
                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Login Log</span>
                           </Link>
                        </li> */}
                        {/* <li className="slide is-expanded">
                           <Link className={props.locationName == props.prefix + '/notification' ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/notification'}>
                              <svg xmlns="http://www.w3.org/2000/svg" className={header?"side-menu__icon res_icon":"side-menu__icon"} width="24" height="24" viewBox="0 0 24 24"> <path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z"> </path> </svg>
                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Notification</span>
                           </Link>
                        </li> */}
                        {/* <li className="slide is-expanded">
                     <Link className="side-menu__item" to={props.prefix}>
                        <i className="fa-regular fa-thumbs-up fa-fw side-menu__icon fs-18"></i>
                        <span className={header?"side-menu__label d-none":"side-menu__label"}>Follow Up</span>
                     </Link>
                  </li> */}
                        {/* <li className="slide">
                           <Link className="side-menu__item" data-bs-toggle="slide" to="/">
                              <i className={header?"fa-solid fa-gear fa-fw side-menu__icon fs-18 res_icon":"fa-solid fa-gear fa-fw side-menu__icon fs-18"}></i>
                              <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Setting</span><i className="angle fa fa-chevron-right"></i>
                           </Link>
                           <ul className="slide-menu">
                              <li className="side-menu-label1"><Link to={props.prefix}>Setting</Link></li>
                              <li><Link to={props.prefix + '/state'} className="slide-item"> State</Link></li>
                              <li><Link to={props.prefix + '/city'} className="slide-item"> City</Link></li>
                              <li><Link to={props.prefix + '/pincode'} className="slide-item"> Pincode</Link></li>
                              <li><Link to={props.prefix + '/status'} className="slide-item"> Incomplete Status</Link></li>
                           </ul>
                        </li> */}

                     </ul>
                     :

                     <div>

                        <ul className="side-menu app-sidebar3 open" style={{ marginRight: "0px" }}>
                           {/* <li className="side-item side-item-category">Main</li> */}
                           <li className="slide is-expanded" style={dashMenu ? { display: "block" } : { display: "none" }}>
                              <Link className={props.locationName == (props.prefix + '/dashboard' || props.prefix) ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/dashboard'}>
                                 <svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" width="24" height="24" viewBox="0 0 24 24">
                                    <path d="M3 13h1v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7h1a1 1 0 0 0 .707-1.707l-9-9a.999.999 0 0 0-1.414 0l-9 9A1 1 0 0 0 3 13zm7 7v-5h4v5h-4zm2-15.586 6 6V15l.001 5H16v-5c0-1.103-.897-2-2-2h-4c-1.103 0-2 .897-2 2v5H6v-9.586l6-6z"></path>
                                 </svg>
                                 <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Dashboard</span>
                              </Link>
                           </li>
                           <li className="slide is-expanded" style={partnerMenu ? { display: "block" } : { display: "none" }}>
                              <Link className={props.locationName == props.prefix + '/dsa' ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/dsa'}>
                                 <svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" width="24" height="24" viewBox="0 0 24 24">
                                    <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                                 </svg>
                                 <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Partner Report</span>
                              </Link>
                           </li>
                           <li className="slide is-expanded" style={lenderMenu ? { display: "block" } : { display: "none" }}>
                              <Link className={props.locationName == props.prefix + '/lender' ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/lender'}>
                                 <svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" width="24" height="24" viewBox="0 0 24 24">
                                    <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                                 </svg>
                                 <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Lenders Report</span>
                              </Link>
                           </li>
                           <li className="slide is-expanded" style={lenderMenu ? { display: "block" } : { display: "none" }}>
                              <Link className={props.locationName == props.prefix + '/merchant' ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/merchant'}>
                                 <svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" width="24" height="24" viewBox="0 0 24 24">
                                    <path d="M19.937 8.68c-.011-.032-.02-.063-.033-.094a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.99.99 0 0 0-.05-.258zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
                                 </svg>
                                 <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Merchant Report</span>
                              </Link>
                           </li>
                           <li className="slide is-expanded" style={caseMenu ? { display: "block" } : { display: "none" }}>
                              <Link className={props.locationName == props.prefix + '/cases' || props.locationName == props.prefix + '/cases-' + props.caseStatus || props.locationName == props.prefix + '/cases-dsa/' + props.dsa_id || props.locationName == props.prefix + '/cases-lender/' + props.lender_id ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/cases'}>
                                 <svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" width="24" height="24" viewBox="0 0 24 24"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"> </path> </svg>
                                 <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Cases Report</span>
                              </Link>
                           </li>


                           {/* <li className="slide">
                     <Link to="" className="side-menu__item" data-bs-toggle="slide" >
                       <i className="fa-solid fa-database fa-fw side-menu__icon fs-18"></i>
                        <span className={header?"side-menu__label d-none":"side-menu__label"}>Report</span><i className="angle fa fa-chevron-right"></i>
                     </Link>
                     <ul className="slide-menu">
                        <li className="side-menu-label1"><Link to={props.prefix}>Report</Link></li>
                        <li><Link to={props.prefix} className="slide-item"> Cases Report</Link></li>
                        <li><Link to={props.prefix} className="slide-item"> Partner Wise Report</Link></li>
                        <li><Link to={props.prefix} className="slide-item"> Lender Wise Report</Link></li>
                        <li><Link to={props.prefix} className="slide-item"> Disbursed Report</Link></li>
                     </ul>
                  </li> */}
                           {/* <li className="slide is-expanded">
                     <Link className="side-menu__item" to={props.prefix+'/query-builder'}>
                        <i className="fa-regular fa-user fa-fw side-menu__icon fs-18"></i>
                        <span className={header?"side-menu__label d-none":"side-menu__label"}>Query Builder</span>
                     </Link>
                  </li> */}
                           <li className="slide is-expanded" style={profileMenu ? { display: "block" } : { display: "none" }}>
                              <Link className={props.locationName == props.prefix + '/profile' || props.locationName == props.prefix + '/add-profile' || props.locationName == props.prefix + '/edit-profile/' + props.profile_id ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/profile'}>
                                 <i className="fa-regular fa-address-card fa-fw side-menu__icon fs-18"></i>
                                 <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Profile</span>
                              </Link>
                           </li>
                           <li className="slide is-expanded" style={profileMenu ? { display: "block" } : { display: "none" }}>
                              <Link className={props.locationName == props.prefix + '/user' || props.locationName == props.prefix + '/add-user' || props.locationName == props.prefix + '/edit-user/' + props.user_id ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/user'}>
                                 <i className=" fa fa-users side-menu__icon fs-18"></i>
                                 <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Users</span>
                              </Link>
                           </li>
                           <li className="slide is-expanded" style={logMenu ? { display: "block" } : { display: "none" }}>
                              <Link className={props.locationName == props.prefix + '/login-log' ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/login-log'}>
                                 <svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" width="24" height="24" viewBox="0 0 24 24"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33C4.62 15.49 4 13.82 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"> </path> </svg>
                                 <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Login Log</span>
                              </Link>
                           </li>
                           <li className="slide is-expanded" style={notificationMenu ? { display: "block" } : { display: "none" }}>
                              <Link className={props.locationName == props.prefix + '/notification' ? "side-menu__item active" : "side-menu__item"} to={props.prefix + '/notification'}>
                                 <svg xmlns="http://www.w3.org/2000/svg" className="side-menu__icon" width="24" height="24" viewBox="0 0 24 24"> <path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z"> </path> </svg>
                                 <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Notification</span>
                              </Link>
                           </li>
                           {/* <li className="slide is-expanded">
                     <Link className="side-menu__item" to={props.prefix}>
                        <i className="fa-regular fa-thumbs-up fa-fw side-menu__icon fs-18"></i>
                        <span className={header?"side-menu__label d-none":"side-menu__label"}>Follow Up</span>
                     </Link>
                  </li> */}
                           <li className="slide" style={settingMenu ? { display: "block" } : { display: "none" }}>
                              <Link className="side-menu__item" data-bs-toggle="slide" to="/">
                                 <i className="fa-solid fa-gear fa-fw side-menu__icon fs-18"></i>
                                 <span className={header ? "side-menu__label d-none" : "side-menu__label"}>Setting</span><i className="angle fa fa-chevron-right"></i>
                              </Link>
                              <ul className="slide-menu" style={settingMenu ? { display: "block" } : { display: "none" }}>
                                 <li className="side-menu-label1"><Link to={props.prefix}>Setting</Link></li>
                                 <li><Link to={props.prefix + '/state'} className="slide-item"> State</Link></li>
                                 <li><Link to={props.prefix + '/city'} className="slide-item"> City</Link></li>
                                 <li><Link to={props.prefix + '/pincode'} className="slide-item"> Pincode</Link></li>
                                 <li><Link to={props.prefix + '/status'} className="slide-item"> Incomplete Status</Link></li>
                              </ul>
                           </li>

                        </ul>

                     </div>


                  }
                  <div className="slide-right" id="slide-right">
                     <svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"></path>
                     </svg>
                  </div>
               </div>
               <div className="ps__rail-x" style={{ left: "0px", bottom: "-300px" }}>
                  <div className="ps__thumb-x" tabIndex="0" style={{ left: "0px", width: "0px" }}></div>
               </div>
               <div className="ps__rail-y" style={{ top: "300px", height: "657px", right: "0px" }}>
                  <div className="ps__thumb-y" tabIndex="0" style={{ top: "204px", height: "446px" }}></div>
               </div>
            </aside>
         </div>

      </>
   )
}

export default Header;