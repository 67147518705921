import React, { useState, useEffect, useRef } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import PaginationComponent from '../../PaginationComponent';
import { getApi, postApi } from '../service/adminService';
import Loader from '../../components/Loader/loader';

const FeedBack = () => {
    const [loader, setloader] = useState(true)
    const [getLenderDetail, setGetLenderDetail] = useState([])
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const [sortedBy, setSortedBy] = useState("id")
    const [orderBy, setOrderBy] = useState('desc')
    const [lenderId, setLenderId] = useState('')
    const admin = JSON.parse(localStorage.getItem("admin"));

    const getAllCityData = async (token, pageNumber, filterVlu) => {
        const response = await getApi("/ml-feedback", { page_number: pageNumber, search: filterVlu, sortby: sortedBy, orderby: orderBy });

        if (response.status) {
            setTimeout(() => {
                setDataArray(response.data.rows);
                setTotalPagesCount(response.data.count);
                // settotalItems(response.data)
                setloader(false)
            }, 2000);
            setloader(true)
        } else {
            console.log("get city data response", response);
        }
    }

    useEffect(() => {
        getAllCityData(admin.admin_token, pagesCount, search);
    }, [search, pagesCount]);

    const GetLenderList = async () => {
        const response = await getApi('/get-lender-list');
        if (response.status) {
            setGetLenderDetail(response.data);
        } else {
            console.log("get employees data response", response);
        }
    }

    useEffect(() => {
        GetLenderList();
    }, []);

    const GenerateFineTune = async () => {
        // setLenderId(e.target.value)
        const response = await postApi("/fine-tune", {
            lender_id: lenderId
        })
        if (response.status == true) {
            toast.success(response.message)
            setLenderId('')
        } else {
            console.log(response.message)
        }
    }

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("asc")
        }
        getAllCityData(admin.admin_token, pagesCount, search);
    }

    return (

        <>
            <Toaster position="top-right" reverseOrder={false} />
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            {/* <div className="page-leftheader">
                                <h4 className="page-title mb-0 text-primary">City({dataArray.length})</h4>
                            </div> */}
                            <div className="page-leftheader">
                                {/* <h6 className="page-title mb-0 text-primary">New Borrower</h6> */}
                                <h6 className="page-title mb-0 text-primary">
                                    <nav aria-label="breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item"><a >Setting</a></li>
                                            <li className="breadcrumb-item active" aria-current="page">Feedback Detail({dataArray.length})</li>
                                        </ol>
                                    </nav>
                                </h6>
                            </div>

                        </div>
                        <div className="row">

                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label"> Lender List</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <select className="form-control" onChange={(e) => setLenderId(e.target.value)}>
                                                <option value=''>-- Select lender First --</option>
                                                {getLenderDetail.map(item => (<option key={item.id} value={item.user_id}>{item.company_name ? item.company_name : "NA"} -- {item.mobile_number ? item.mobile_number : ""} </option>))}
                                                {getLenderDetail.length == 0 &&
                                                    <option className='text-bold'>No Lender Available</option>

                                                }
                                            </select>
                                            <span className="input-group-append h-80">
                                                <button className="btn btn-primary" type="button" disabled={!lenderId}
                                                    onClick={() => GenerateFineTune()}>
                                                    Fine Tune</button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">Loan Id</th>
                                                        <th className="font-weight-bold">Borrower Detail</th>
                                                        <th className="font-weight-bold">Lender Detail</th>
                                                        <th className="font-weight-bold">Ratings</th>
                                                        <th className="font-weight-bold">Ratings</th>

                                                        {/* <th className="font-weight-bold">bank statement analysis</th> */}
                                                        {/* <th className="font-weight-bold">behavioral analysis</th>
                                                        <th className="font-weight-bold">conclusion</th>
                                                        <th className="font-weight-bold">credit score outcome</th> */}

                                                        {/* <th className="font-weight-bold">loan eligibility calculation</th>
                                                        <th className="font-weight-bold">overall report</th>
                                                        <th className="font-weight-bold">payslip salary analysis</th>
                                                        <th className="font-weight-bold">risk indicator analysis</th> */}
                                                        <th className="font-weight-bold">Analysis Accurate</th>
                                                        <th className="font-weight-bold">Decision making</th>
                                                        <th className="font-weight-bold">discrepancies or missing details</th>
                                                        <th className="font-weight-bold">how can this report be improved</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                {loader == true ? <tbody>
                                                    <td /><td /><td /><td /><td />

                                                    <Loader />
                                                </tbody> :
                                                    <tbody>
                                                        {dataArray.length > 0 && dataArray.map((option, index) => {
                                                            const { user_loan_id, bank_statement_analysis, behavioral_analysis, conclusion, credit_score_outcome, loan_eligibility_calculation, overall_report, payslip_salary_analysis, risk_indicator_analysis, how_can_this_report_be_improved, discrepancies_or_missing_details } = option
                                                            let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                            return (
                                                                <tr key={option.id} >
                                                                    <td><span className="font-weight-normal1">{option.user_loan_id ? user_loan_id : ""}</span></td>
                                                                    <td>
                                                                        {/* <Link className="text-primary" to={prefix + `/lenderdetail/${option.user_id}`}> */}
                                                                        <span className="font-weight-normal1" style={{ color: "#0f2f82" }}>
                                                                            {option?.loanApplication?.first_name !== null ? option?.loanApplication?.first_name : ""} {option?.loanApplication?.last_name !== null && option?.loanApplication?.last_name}
                                                                            <br /> {option?.loanApplication?.mobile_number && option?.loanApplication?.mobile_number}
                                                                        </span>
                                                                        {/* </Link> */}
                                                                    </td>
                                                                    <td>
                                                                        <span className="font-weight-normal1" style={{ color: "#0f2f82" }}>
                                                                            {option?.lenderData?.full_name !== null ? option?.lenderData?.full_name : ""} <br />{option?.lenderData?.company_name !== null && option?.lenderData?.company_name}
                                                                            <br /> {option?.lenderData?.mobile_number && option?.lenderData?.mobile_number}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span className="font-weight-normal1">
                                                                            <div className="d-flex ">
                                                                                {/* Left Column - Headings */}
                                                                                <div className="font-weight-normal1 text-muted" style={{ marginRight: "15px" }}>
                                                                                    <span className="d-block">Bank Statement Analysis:</span>
                                                                                    <span className="d-block">Behavioral Analysis:</span>
                                                                                    <span className="d-block">Conclusion:</span>
                                                                                    <span className="d-block">Credit Score Outcome:</span>
                                                                                </div>
                                                                                {/* Right Column - Responses */}
                                                                                <div className="">
                                                                                    <span className="d-block">{option.bank_statement_analysis ? bank_statement_analysis + " Star" : "--"}</span>
                                                                                    <span className="d-block">{option.behavioral_analysis ? behavioral_analysis + " Star" : "--"}</span>
                                                                                    <span className="d-block">{option.conclusion ? conclusion + " Star" : "--"}</span>
                                                                                    <span className="d-block">{option.credit_score_outcome ? credit_score_outcome + " Star" : "--"}</span>
                                                                                </div>
                                                                            </div>
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span className="font-weight-normal1">
                                                                            <div className="d-flex">
                                                                                {/* Left Column - Headings */}
                                                                                <div className="font-weight-normal1 text-muted" style={{ marginRight: "10px" }}>
                                                                                    <span className="d-block">Loan Eligibility Calculation:</span>
                                                                                    <span className="d-block">Overall Report:</span>
                                                                                    <span className="d-block">Payslip Salary Analysis:</span>
                                                                                    <span className="d-block">Risk Indicator Analysis:</span>
                                                                                </div>
                                                                                {/* Right Column - Responses */}
                                                                                <div className="font-weight-bold text-dark">
                                                                                    <span className="d-block">{option.loan_eligibility_calculation ? loan_eligibility_calculation + " Star" : "--"}</span>
                                                                                    <span className="d-block">{option.overall_report ? overall_report + " Star" : "--"}</span>
                                                                                    <span className="d-block">{option.payslip_salary_analysis ? payslip_salary_analysis + " Star" : "--"}</span>
                                                                                    <span className="d-block">{option.risk_indicator_analysis ? risk_indicator_analysis + " Star" : "--"}</span>
                                                                                </div>
                                                                            </div>
                                                                        </span>
                                                                    </td>

                                                                    {/* <td><span className="font-weight-normal1"></span></td>
                                                                    <td><span className="font-weight-normal1"></span></td>
                                                                    <td><span className="font-weight-normal1"></span></td> */}
                                                                    {/* <td><span className="font-weight-normal1"></span></td>
                                                                    <td><span className="font-weight-normal1"></span></td>
                                                                    <td><span className="font-weight-normal1"></span></td>
                                                                    <td><span className="font-weight-normal1"></span></td> */}
                                                                    <td><span className="font-weight-normal1">{option.was_the_analysis_accurate == '1' ? "Yes" : "No"}</span></td>
                                                                    <td><span className="font-weight-normal1">{option.was_the_analysis_useful_for_decision_making == '1' ? "Yes" : "No"}</span></td>
                                                                    <td><span className="font-weight-normal1">{option.discrepancies_or_missing_details ? discrepancies_or_missing_details : "--"}</span></td>
                                                                    <td><span className="font-weight-normal1">{option.how_can_this_report_be_improved ? how_can_this_report_be_improved : "--"}</span></td>
                                                                </tr>
                                                            )
                                                        }
                                                        )}
                                                    </tbody>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {pagesCount > 0 && dataArray.length > 0 ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                        </div>
                    </div>
                </div>
            }

        </>
    )
}



export default FeedBack;
