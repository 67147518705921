import Header from '../website/header';
import Footer from '../website/footer';
const Bnpl = () => {

    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.1/css/all.min.css" />

    return (
        <>
            <Header />

            <section className="section-padding section-bg">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 margin-md-60">
                            <h1 className="display-3 mb-0 fweight">Apply & get credit in a few minutes</h1>
                            <p className="mb-3">Fully digitized process. No need to visit any branches. Get the best rates <br /> Download our app now</p>
                            <div className="qr-codeimg mb-3">
                                <a href="https://play.google.com/store/apps/details?id=com.app.thirdroc" target='blank'><img src="website/assets/img/qr-code.png" alt="" /></a>
                            </div>

                            {/* <p className="mb-3">Loan, BNPL, Earned Wage Access, Credit, Cashbacks</p> */}

                            <h6 className="employeePage ms-0">Loan, BNPL, Earned Wage Access, Credit, Cashbacks</h6>

                            {/* <a href="./website/assets/img/pdf/Thirdroc-Financial-Wellness-Program.pdf" className="employeePage" target="_blank"><i className="fa-regular fa-file-pdf"></i> Employees Wellness, Learn More</a> */}
                        </div>
                        <div className="col-lg-5 offset-lg-1"><img src="website/assets/img/bnpl-2.png" alt="" className="" /></div>
                    </div>
                </div>
            </section>


            {/*<div className="intro-four bgimage">
            <div className="bg_image_holder" style={{backgroundImage: "url('./website/assets/img/slider_bg4.jpg')", opacity: 1 }}><img src="./website/assets/img/slider_bg4.jpg" alt="BNPL" /></div>
            <div className="intro-four--contents content_above">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                        <h1 className="display-3 mb-0">Buy Now Pay Later with ThirdRoc</h1>
                        <p className="mb-3">Welcome to ThirdRoc's Buy Now Pay Later! Shop now and pay later with us. Let us take the burden of upfront payment off your shoulders.</p>

                        <div className="qr-codeimg mb-3">
                             <a href="https://play.google.com/store/apps/details?id=com.app.thirdroc" target='blank'><img src="website/assets/img/qr-code.png" alt=""/></a>
                        </div>
                          
                        <a href="./website/assets/img/pdf/Buy-Now-Pay-Later-with-ThirdRoc.pdf" className="bnplPage" target="_blank"><i className="fa-regular fa-file-pdf"></i> BNPL, Learn More</a>
                        <a href="./website/assets/img/pdf/Thirdroc-Financial-Wellness-Program.pdf" className="employeePage" target="_blank"><i className="fa-regular fa-file-pdf"></i> Employees Wellness, Learn More</a>

                        </div>
                    </div>
                </div>
            </div>
    </div>*/}

            <section className="p-top-100 p-bottom-35 bgwhite">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="m-bottom-65">
                                <div className="divider text-center">
                                    <h1>Why Use Buy Now Pay Later?</h1>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-6">
                            <div className="whyBoxUse">
                                <h3>Flexible Payments</h3>
                                <p>Buy the items you need now and pay later. Our services are flexible and easy to manage, making your shopping experience convenient.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="whyBoxUse">
                                <h3>No Need for Cash</h3>
                                <p>Forget about carrying cash while shopping. Our services allow you to purchase anything you need without the need for hard cash.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="whyBoxUse">
                                <h3>Improve Credit Score</h3>
                                <p>Shop with confidence while improving your credit score. Our services allow you to make timely payments, thus skyrocketing your credit score.</p>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="whyBoxUse">
                                <h3>Avoid Interest</h3>
                                <p>Shopping with us saves you interest fees. Pay in installments, without incurring any interest on your purchases..</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="p-top-100 p-bottom-35 section-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="m-bottom-65">
                                <div className="divider text-center">
                                    <h1>How Buy Now Pay Later Works</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="process-wrapper section-bg process--two">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="process-one">
                                    <div className="process-single text-center">
                                        <div className="icon light-bg"><span className="icon-la">Step 1</span></div>
                                        <h2>Shop</h2>
                                        <p>Browse our partner stores and add to cart the items you desire to purchase</p>
                                    </div>
                                    <div className="process-single text-center">
                                        <div className="icon light-bg"><span className="icon-la">Step 2</span></div>
                                        <h2>Check Out</h2>
                                        <p>Choose the Buy Now Pay Later option at checkout and follow the instructions provided.</p>
                                    </div>
                                    <div className="process-single text-center">
                                        <div className="icon light-bg"><span className="icon-la">3</span></div>
                                        <h2>Pay Later</h2>
                                        <p>Get instant approval and pay later in installments with no interest.</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="p-top-100 p-bottom-35 bgwhite">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-3">
                                <div className="divider text-center">
                                    <h1>Benefits of Using Buy Now Pay Later</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center justify-content-center">
                        <div className="tm-box-col-wrapper col-lg-4 col-sm-6 col-md-6 col-xs-12 ">
                            <article className="themetechmount-box themetechmount-box-service themetechmount-box-view-style-one themetechmount-service-box-view-style-one">
                                <div className="themetechmount-post-item">
                                    <div className="themetechmount-post-item-inner">
                                        <div className="tm-featured-wrapper tm-tm_service-featured-wrapper">
                                            <a href="#"><img src="website/assets/img/benefits-1.jpg" className="wp-post-image" alt="" /></a>
                                        </div>
                                    </div>

                                    <div className="themetechmount-box-bottom-content">
                                        <div className="themetechmount-box-title"><h4><a href="#">Shop More</a></h4></div>
                                        <div className="themetechmount-box-desc">
                                            Buy anything you desire while maintaining your budget. The buy now pay later option allows you to shop even if you are out of funds.
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div className="tm-box-col-wrapper col-lg-4 col-sm-6 col-md-6 col-xs-12 ">
                            <article className="themetechmount-box themetechmount-box-service themetechmount-box-view-style-one themetechmount-service-box-view-style-one">
                                <div className="themetechmount-post-item">
                                    <div className="themetechmount-post-item-inner">
                                        <div className="tm-featured-wrapper tm-tm_service-featured-wrapper">
                                            <a href="#"><img src="website/assets/img/benefits-2.jpg" className="wp-post-image" alt="" /></a>
                                        </div>
                                    </div>

                                    <div className="themetechmount-box-bottom-content">
                                        <div className="themetechmount-box-title"><h4><a href="#">Save Money</a></h4></div>
                                        <div className="themetechmount-box-desc">
                                            Use our services and save money. No interest is charged while making payments in installments. This means more buying power and fewer expenses.
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div className="tm-box-col-wrapper col-lg-4 col-sm-6 col-md-6 col-xs-12 ">
                            <article className="themetechmount-box themetechmount-box-service themetechmount-box-view-style-one themetechmount-service-box-view-style-one">
                                <div className="themetechmount-post-item">
                                    <div className="themetechmount-post-item-inner">
                                        <div className="tm-featured-wrapper tm-tm_service-featured-wrapper">
                                            <a href="#"><img src="website/assets/img/benefits-3.jpg" className="wp-post-image" alt="" /></a>
                                        </div>
                                    </div>

                                    <div className="themetechmount-box-bottom-content">
                                        <div className="themetechmount-box-title"><h4><a href="#">Convenient Shopping</a></h4></div>
                                        <div className="themetechmount-box-desc">
                                            Shop with ease and convenience. Forget about the complexity of traditional payment methods. Buy now pay later makes shopping easy.
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>




            <div className="p-top-80 p-bottom-80 section-bg">
                <div className="accordion-styles section-bg accordion--two">
                    <div className="container">

                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-5">
                                    <div className="divider text-center">
                                        <h1>FAQs About Buy Now Pay Later</h1>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-6 margin-md-60">
                                <div className="accordion accordion_four" id="accordion_4_2">
                                    <div className="accordion-single">
                                        <div className="accordion-heading" id="accordion_4_2_heading1">
                                            <h6 className="mb-0">
                                                <a href="#" className="" data-toggle="collapse" data-target="#accordion_4_2_collapse1" aria-expanded="true" aria-controls="accordion_4_2_collapse1">
                                                    Q: How do I apply for the buy now pay later service?
                                                </a>
                                            </h6>
                                        </div>

                                        <div id="accordion_4_2_collapse1" className="collapse show" aria-labelledby="accordion_4_2_heading1" data-parent="#accordion_4_2">
                                            <div className="accordion-contents">
                                                <p>A: You can apply through our website or during checkout at our partner stores.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-single">
                                        <div className="accordion-heading" id="accordion_4_2_heading2">
                                            <h6 className="mb-0">
                                                <a href="#" className="collapsed" data-toggle="collapse" data-target="#accordion_4_2_collapse2" aria-expanded="false" aria-controls="accordion_4_2_collapse2">
                                                    Q: Can I make early payments?
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="accordion_4_2_collapse2" className="collapse" aria-labelledby="accordion_4_2_heading2" data-parent="#accordion_4_2">
                                            <div className="accordion-contents">
                                                <p>A: Yes, you can make early payments without any penalty.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-single">
                                        <div className="accordion-heading" id="accordion_4_2_heading3">
                                            <h6 className="mb-0">
                                                <a href="#" className="collapsed" data-toggle="collapse" data-target="#accordion_4_2_collapse3" aria-expanded="false" aria-controls="accordion_4_2_collapse3">
                                                    Q: Will my credit score be affected?
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="accordion_4_2_collapse3" className="collapse" aria-labelledby="accordion_4_2_heading3" data-parent="#accordion_4_2">
                                            <div className="accordion-contents">
                                                <p>A: No, we don't report to credit bureaus. We rather focus on helping you build your credit score.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="accordion-single">
                                        <div className="accordion-heading" id="accordion_4_2_heading4">
                                            <h6 className="mb-0">
                                                <a href="#" className="collapsed" data-toggle="collapse" data-target="#accordion_4_2_collapse4" aria-expanded="false" aria-controls="accordion_4_2_collapse4">
                                                    Q: Are there any additional fees?
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="accordion_4_2_collapse4" className="collapse" aria-labelledby="accordion_4_2_heading4" data-parent="#accordion_4_2">
                                            <div className="accordion-contents">
                                                <p>A: No, we don't charge any fees, including hidden fees. Buy now pay later is completely free.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 offset-lg-1">
                                <img src="website/assets/img/bnpl-1.png" alt="" />
                            </div>
                        </div>

                    </div>
                </div>
            </div>





            <div className="p-top-80 p-bottom-80 section-bg bgwhite">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-5">
                                <div className="divider text-center">
                                    <h1>Our Partners and Where to Use Buy Now Pay Later</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-block section-feature-boxes VisibleOve">
                    <div className="container">
                        <div className="section-img dMobileNone">
                            <img src="website/assets/img/slider_3.png" alt="" />
                        </div>
                        <div className="row">
                            <div className="col-lg-7 offset-lg-5">
                                <div className="feature-boxes row">
                                    <div className="col-md-6">
                                        <div className="icon-box-nine text-center fontSize24">
                                            <h6>Luxury stores</h6>
                                            <p className="">Get anything you need from Prouds stores using our buy now pay later option.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="icon-box-nine text-center fontSize24">
                                            <h6>Supermarkets</h6>
                                            <p className="">Shop for groceries and more using our BNPL with our supermarket partners.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="icon-box-nine text-center fontSize24">
                                            <h6>Hardware stores</h6>
                                            <p className="">Buy all the tools and items you require to repair or construct anything at our hardware merchants using our buy now pay later option.</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="icon-box-nine text-center fontSize24">
                                            <h6>Furniture stores</h6>
                                            <p className="">Purchase furniture and home décor items from our partner's using our buy now pay later option.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>





            <Footer />
        </>
    )
}

export default Bnpl;