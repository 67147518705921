import React, { useEffect, useState } from "react";
import { getApi, postApi } from "../service/lenderService";
import { Link, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import Loader from "../../components/Loader/loader";
import { Image } from "antd";
import TypeWriterEffect from "react-typewriter-effect";
import StarRatings from "react-star-ratings";
import FeedbackModal from "./FeedbackModal";

const Overallborrowerinformation = () => {
    const [employeeDetail, setEmployeeDetail] = useState(Object);
    const [employeeDocs, setEmployeeDocs] = useState(Object);
    const [analysisResponse, setAnalysisResponse] = useState([]);
    const [getJSOnfile, setGetJSONfile] = useState("");
    const [getMLresponse, setGetMLresponse] = useState(null);
    const [modalStatus, setModalStatus] = useState(false)
    const [status, setStatus] = useState(true);
    const [loader2, setLoader2] = useState(true);
    const  [staticHeading , setStaticHeading] = useState([{key:"Credit Score Calculator"},
    {key:"Debt-To-Income Ratio Calculator"},
    {key:"Bank Statement Analyser"},
    {key:"Salary Reader"},
    {key:"Risk Indicator"},
    {key:"Eligibility Of Loan Analyser"},
    {key:'Conclusion'}
])
    // const [showStaticHeadings, setShowStaticHeadings] = useState(""); // Controls static heading display
    const [displayedHeadings, setDisplayedHeadings] = useState([]); // Stores the static headings that should be displayed
    const [loader, setLoader] = useState(false);
    const params = useParams();
    const viewBorrowerEWADetail = async () => {
        try {
            const response = await getApi(`/user-loan-application/${params.id}`);
            if (response.status === true) {
                setTimeout(() => {
                    setLoader(false);
                }, 1000);
                setEmployeeDetail(response.data.userData);
                setEmployeeDocs(response?.data?.docsData);
            } else {
                console.log(response);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        viewBorrowerEWADetail();
    }, []);

    const Total_Liabilities =
        parseFloat(
            employeeDetail?.mortgage_loan_balnce
                ? employeeDetail?.mortgage_loan_balnce
                : 0
        ) +
        parseFloat(employeeDetail?.home_loan ? employeeDetail?.home_loan : 0) +
        parseFloat(
            employeeDetail?.otherloan_balance ? employeeDetail?.otherloan_balance : 0
        );
    const annualSalary = parseFloat(
        employeeDetail?.annual_salary ? employeeDetail?.annual_salary : 0
    );
    const otherIncome = parseFloat(
        employeeDetail?.other_income ? employeeDetail?.other_income : 0
    );

    const Total_Monthly_Income = (annualSalary / 12 + otherIncome).toFixed(2);  

    const getMLanaysisFileName = async () => {
        try {
            const response = await getApi(`/new-ml-analysis/${params.id}`);
            // console.log(response,"fjdkffffffffff")
            if (response.status) {
                // console.log(response);
                setGetJSONfile(response?.fileName);
                StoreErrorLogs("ML analysis JSON file uploaded in input bucket.");
            }
        } catch (error) {
            console.log(error.message);
        }
    };

    useEffect(() => {
        getMLanaysisFileName();
    }, []);


    const PostnewMLanalysis = async () => {
        try {
            const maxRetries = 6;
            const delay = 30000; // 30 seconds
            for (let attempt = 0; attempt < maxRetries; attempt++) {
                const response = await postApi("/new-ml-analysis-response", {
                    jsonFileName: getJSOnfile,
                });

                if (response.status) {
                    setGetMLresponse(response.data);
                    StoreErrorLogs("ML analysis response fetched successfully.");
                    return;
                } else {
                    console.log(response);
                    StoreErrorLogs(response?.message);
                }

                await new Promise((resolve) => setTimeout(resolve, delay));
            }
            // toast.error("looks like something went wrong.");
            setStatus(false);
        } catch (error) {
            console.log(error.message);
        } finally {
            // setLoader2(false)
        }
    };

    const StoreErrorLogs = async (error) => {
        console.log(error);
        try {
            const response = await postApi("/save-ml-error", {
                user_loan_id: params.id,
                error: error,
            });
            if (response.status) {
                console.log(response.message);
            } else {
                console.log(response.message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (getJSOnfile !== "") {
            const timer = setTimeout(() => {
                PostnewMLanalysis();
            }, 30000); // 30000 milliseconds = 30 seconds

            // Cleanup function to clear the timeout if the component unmounts or if getJSOnfile changes
            return () => clearTimeout(timer);
        }
    }, [getJSOnfile]);

  
    useEffect(() => {
        if (getMLresponse) {
            const allResponses = Object.entries(getMLresponse).map(([key, value]) => {
                const lines = value.Response.replace(/\\+/g, "")
                    .replace(/\*/g, "")
                    .replace(/#/g, "")
                    .replace(/\[/g, "")
                    .replace(/\]/g, "")
                    .split('\n');
                return { key, lines };
            });

            setAnalysisResponse(allResponses);
            setLoader2(false);

            // Start showing static headings
            showStaticHeadings();
        }
    }, [getMLresponse]);

    const showStaticHeadings = () => {
        setDisplayedHeadings([]); // Clear previous headings
        staticHeading.forEach((heading, index) => {
            const timeoutId = setTimeout(() => {
                setDisplayedHeadings((prevHeadings) => [...prevHeadings, heading]);
                // After the last heading is displayed, stop showing the static headings
                if (index === staticHeading.length - 1) {
                    setTimeout(() => setDisplayedHeadings([]), 2000); // Clear headings after last one shows
                }
            }, index * 2000); // Show each heading after 2 seconds interval

            // Cleanup function to clear timeout
            return () => clearTimeout(timeoutId);
        });
    };


    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header mb-3">
                <div className="page-leftheader">
                    {/* <h4 className="page-title mb-0 text-primary">Overall Borrower Information</h4> */}
                    <h4 className="page-title mb-0 text-primary">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link
                                        className="breadcrumbslink"
                                        to="/lender/application_agent"
                                    >
                                        Application Agent
                                    </Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Overall Borrower Information
                                </li>
                            </ol>
                        </nav>
                    </h4>
                </div>
                <div className="page-rightheader"></div>
                <button className="btn btn-outline-primary me-3" /* data-bs-toggle="modal" data-bs-target="#staticBackdrop" */ onClick={() => setModalStatus(true)}>Feedback</button>
            </div>
            <FeedbackModal modalStatus={modalStatus} setModalStatus={setModalStatus} />


            {/*********************************** PERSONAL INFORMATION *******************************************/}

            <div class="row">
                <div class="col-sm-7">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title text-primary">Personal Information</h4>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6 className="text-secondary fs-12">
                                        Required EWA Advance Amount
                                    </h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.require_loan_amount
                                            ? employeeDetail?.require_loan_amount
                                            : "--"}
                                    </p>
                                </div>
                                <div>
                                    {" "}
                                    <h6 className="text-secondary fs-12">EWA Advance Type</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        Personal
                                    </p>
                                </div>

                                <div>
                                    <h6 className="text-secondary fs-12">TIN</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.tin ? employeeDetail?.tin : "--"}
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6 className="text-secondary fs-12">FNPF</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.fnpf ? employeeDetail?.fnpf : "--"}
                                    </p>
                                </div>
                                <div>
                                    {" "}
                                    <h6 className="text-secondary fs-12">First Name</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.first_name
                                            ? employeeDetail?.first_name
                                            : ""}
                                    </p>
                                </div>

                                <div>
                                    <h6 className="text-secondary fs-12">Middle Name</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.middle_name
                                            ? employeeDetail?.middle_name
                                            : "--"}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6 className="text-secondary fs-12">Last Name</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.last_name
                                            ? employeeDetail?.last_name
                                            : "--"}
                                    </p>
                                </div>
                                <div>
                                    {" "}
                                    <h6 className="text-secondary fs-12">Personal Email</h6>
                                    <p
                                        style={{
                                            width: "13rem",
                                            lineHeight: "inherit",
                                            textTransform: "none",
                                        }}
                                    >
                                        {employeeDetail?.personal_email
                                            ? employeeDetail?.personal_email
                                            : "--"}
                                    </p>
                                </div>

                                <div>
                                    <h6 className="text-secondary fs-12">Residential Address </h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.residental_address
                                            ? employeeDetail?.residental_address
                                            : "--"}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6 className="text-secondary fs-12">Residential Period</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.period ? employeeDetail?.period : "--"}
                                    </p>
                                </div>
                                <div>
                                    {" "}
                                    <h6 className="text-secondary fs-12">Postal Address</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.postal_address
                                            ? employeeDetail?.postal_address
                                            : "--"}
                                    </p>
                                </div>

                                <div>
                                    <h6 className="text-secondary fs-12">Postal Period</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.postal_period
                                            ? employeeDetail?.postal_period
                                            : "--"}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6 className="text-secondary fs-12">Previous Address</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.previus_address
                                            ? employeeDetail?.previus_address
                                            : "--"}
                                    </p>
                                </div>
                                <div>
                                    {" "}
                                    <h6 className="text-secondary fs-12">Previous Period</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.previous_period
                                            ? employeeDetail?.previous_period
                                            : "--"}
                                    </p>
                                </div>

                                <div>
                                    <h6 className="text-secondary fs-12">Work Email</h6>
                                    <p
                                        style={{
                                            width: "13rem",
                                            lineHeight: "inherit",
                                            textTransform: "none",
                                        }}
                                    >
                                        {employeeDetail?.work_email
                                            ? employeeDetail?.work_email
                                            : "--"}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6 className="text-secondary fs-12">Telephone(H)</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.telephone_home
                                            ? employeeDetail?.telephone_home
                                            : "--"}
                                    </p>
                                </div>
                                <div>
                                    {" "}
                                    <h6 className="text-secondary fs-12">Telephone(W)</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.telephone_work
                                            ? employeeDetail?.telephone_work
                                            : "--"}
                                    </p>
                                </div>

                                <div>
                                    <h6 className="text-secondary fs-12">DOB.</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.date_of_birth
                                            ? employeeDetail?.date_of_birth
                                            : "--"}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6 className="text-secondary fs-12">Marital Status</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.martial_status
                                            ? employeeDetail?.martial_status
                                            : "--"}
                                    </p>
                                </div>
                                <div>
                                    {" "}
                                    <h6 className="text-secondary fs-12">No. of Dependents</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.dependent_no
                                            ? employeeDetail?.dependent_no
                                            : "--"}
                                    </p>
                                </div>

                                <div>
                                    <h6 className="text-secondary fs-12">Occupation</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.occupation
                                            ? employeeDetail?.occupation
                                            : "--"}
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6 className="text-secondary fs-12">Current Employer</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.current_employer
                                            ? employeeDetail?.current_employer
                                            : "--"}
                                    </p>
                                </div>
                                <div>
                                    {" "}
                                    <h6 className="text-secondary fs-12">Employed Since</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.employed_since
                                            ? employeeDetail?.employed_since
                                            : "***"}
                                    </p>
                                </div>

                                <div>
                                    <h6 className="text-secondary fs-12">Previous Employer</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.previus_employer
                                            ? employeeDetail?.previus_employer
                                            : "--"}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6 className="text-secondary fs-12">
                                        Previous Employer Address
                                    </h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.previus_office_address
                                            ? employeeDetail?.previus_office_address
                                            : "--"}
                                    </p>
                                </div>
                                <div>
                                    {" "}
                                    <h6 className="text-secondary fs-12">
                                        Previous Employer Period
                                    </h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.previous_employer_period
                                            ? employeeDetail?.previous_employer_period
                                            : "***"}
                                    </p>
                                </div>

                                <div>
                                    <h6 className="text-secondary fs-12">
                                        Own House Estimated Value
                                    </h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.house_est_value
                                            ? employeeDetail?.house_est_value
                                            : "--"}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6 className="text-secondary fs-12">Bank Name (Mortgage)</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.mortgaged
                                            ? employeeDetail?.mortgaged
                                            : "--"}
                                    </p>
                                </div>
                                <div>
                                    {" "}
                                    <h6 className="text-secondary fs-12">
                                        Monthly Repayment Amount
                                    </h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.monthly_payment
                                            ? employeeDetail?.monthly_payment
                                            : "--"}
                                    </p>
                                </div>

                                <div>
                                    <h6 className="text-secondary fs-12">
                                        Remaining Mortgage Balance
                                    </h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.mortgage_balance
                                            ? employeeDetail?.mortgage_balance
                                            : "--"}
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6 className="text-secondary fs-12">Living House Status</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.notliving_house
                                            ? employeeDetail?.notliving_house
                                            : "--"}
                                    </p>
                                </div>
                                <div>
                                    {" "}
                                    <h6 className="text-secondary fs-12">Bank Name</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.bank_name
                                            ? employeeDetail?.bank_name
                                            : "--"}
                                    </p>
                                </div>

                                <div>
                                    <h6 className="text-secondary fs-12">Branch</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.branch ? employeeDetail?.branch : "--"}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6 className="text-secondary fs-12">Account Holder Name</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.account_name
                                            ? employeeDetail?.account_name
                                            : "--"}
                                    </p>
                                </div>
                                <div>
                                    {" "}
                                    <h6 className="text-secondary fs-12">Account No</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.account_number
                                            ? employeeDetail?.account_number
                                            : "--"}
                                    </p>
                                </div>

                                <div>
                                    <h6 className="text-secondary fs-12">
                                        {/* Remaining Mortgage Balance */}
                                    </h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {/* 5412665464564644657675675 */}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <h4 class="card-title text-primary">Income</h4>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6 className="text-secondary fs-12">Pay Cycle</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.pay_cycle
                                            ? employeeDetail?.pay_cycle
                                            : "--"}
                                    </p>
                                </div>
                                <div>
                                    {" "}
                                    <h6 className="text-secondary fs-12">Annual Salary</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.annual_salary
                                            ? "$" + employeeDetail?.annual_salary
                                            : "--"}
                                    </p>
                                </div>

                                <div>
                                    <h6 className="text-secondary fs-12">Other Monthly Income</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.other_income
                                            ? "$" + employeeDetail?.other_income
                                            : "--"}
                                    </p>
                                </div>
                                {/* <div><h6 className='text-secondary fs-12'>Total Monthly Salary</h6>
                                    <p style={{width:'13rem' , lineHeight:'inherit'}}>5412665464564644657675675</p></div> */}
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6 className="text-secondary fs-12">Total Monthly Salary</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {Total_Monthly_Income ? "$" + Total_Monthly_Income : "--"}
                                    </p>
                                </div>
                                <div>
                                    {" "}
                                    <h6 className="text-secondary fs-12">{/* First Name */}</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {/* Personal */}
                                    </p>
                                </div>

                                <div>
                                    <h6 className="text-secondary fs-12">{/* Middle Name */}</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {/* 54126 */}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="card-body">
                            <h4 class="card-title text-primary">Purpose of EWA Advance</h4>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6 className="text-secondary fs-12">
                                        Purpose of EWA Advance
                                    </h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.purpose_of_ewa_advance
                                            ? employeeDetail?.purpose_of_ewa_advance
                                            : "--"}
                                    </p>
                                </div>
                                <div>
                                    {" "}
                                    <h6 className="text-secondary fs-12">
                                        Monthly Business Income
                                    </h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.monthly_business_income
                                            ? "$" + employeeDetail?.monthly_business_income
                                            : "--"}
                                    </p>
                                </div>

                                <div>
                                    <h6 className="text-secondary fs-12">
                                        {/* Other Monthly Income */}
                                    </h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {/* 4541 */}
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/*********************************** ASSEST INFORMATION ********************************************/}

                        <div class="card-body">
                            <h4 class="card-title text-primary">Assets</h4>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6 className="text-secondary fs-12">House Value </h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.house_value
                                            ? "$" + employeeDetail?.house_value
                                            : "--"}
                                    </p>
                                </div>
                                <div>
                                    {" "}
                                    <h6 className="text-secondary fs-12">Car Value</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.car_value
                                            ? "$" + employeeDetail?.car_value
                                            : "--"}
                                    </p>
                                </div>

                                <div>
                                    <h6 className="text-secondary fs-12">Land Value</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.land_value
                                            ? "$" + employeeDetail?.land_value
                                            : "--"}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6 className="text-secondary fs-12">Life Policy</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.life_policy
                                            ? "$" + employeeDetail?.life_policy
                                            : "--"}
                                    </p>
                                </div>
                                <div>
                                    {" "}
                                    <h6 className="text-secondary fs-12">debtors</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.debtors
                                            ? "$" + employeeDetail?.debtors
                                            : "--"}
                                    </p>
                                </div>

                                <div>
                                    <h6 className="text-secondary fs-12">Personal Effects</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.personal_effects
                                            ? "$" + employeeDetail?.personal_effects
                                            : "--"}{" "}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6 className="text-secondary fs-12">Available Cash</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.cash_on_hand
                                            ? "$" + employeeDetail?.cash_on_hand
                                            : "--"}
                                    </p>
                                </div>
                                <div>
                                    {" "}
                                    <h6 className="text-secondary fs-12">Cash At Bank</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.cash_at_bank
                                            ? "$" + employeeDetail?.cash_at_bank
                                            : "--"}
                                    </p>
                                </div>

                                <div>
                                    <h6 className="text-secondary fs-12">
                                        {/* Personal Effects */}
                                    </h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {/* $6868 */}
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/*********************************** Liabilities INFORMATION ********************************************/}
                        <div class="card-body">
                            <h4 class="card-title text-primary">Liabilities</h4>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6 className="text-secondary fs-12">
                                        Mortgage Loan Balance{" "}
                                    </h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.mortgage_loan_balnce
                                            ? "$" + employeeDetail?.mortgage_loan_balnce
                                            : "--"}
                                    </p>
                                </div>
                                <div>
                                    {" "}
                                    <h6 className="text-secondary fs-12">Home Loan</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.home_loan
                                            ? "$" + employeeDetail?.home_loan
                                            : "--"}
                                    </p>
                                </div>

                                <div>
                                    <h6 className="text-secondary fs-12">Other Loan Balance</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.otherloan_balance
                                            ? "$" + employeeDetail?.otherloan_balance
                                            : "--"}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6 className="text-secondary fs-12">Total Liabilities</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {Total_Liabilities ? "$" + Total_Liabilities : 0}
                                    </p>
                                </div>
                                <div>
                                    {" "}
                                    <h6 className="text-secondary fs-12">{/* debtors */}</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {/* $45465 */}
                                    </p>
                                </div>

                                <div>
                                    <h6 className="text-secondary fs-12">
                                        {/* Personal Effects */}
                                    </h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {/* $6868 */}
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/*********************************** Living Cost INFORMATION ********************************************/}

                        <div class="card-body">
                            <h4 class="card-title text-primary">Living Cost</h4>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6 className="text-secondary fs-12">Rent </h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.rent ? "$" + employeeDetail?.rent : "--"}
                                    </p>
                                </div>
                                <div>
                                    {" "}
                                    <h6 className="text-secondary fs-12">Electricity</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.electricity
                                            ? "$" + employeeDetail?.electricity
                                            : "--"}
                                    </p>
                                </div>

                                <div>
                                    <h6 className="text-secondary fs-12">Telephone Cost</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.bill_for_phone
                                            ? "$" + employeeDetail?.bill_for_phone
                                            : "--"}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6 className="text-secondary fs-12">Water</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.bill_for_water
                                            ? "$" + employeeDetail?.bill_for_water
                                            : "--"}
                                    </p>
                                </div>
                                <div>
                                    {" "}
                                    <h6 className="text-secondary fs-12">House Keeping</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.house_keeping
                                            ? "$" + employeeDetail?.house_keeping
                                            : "--"}
                                    </p>
                                </div>

                                <div>
                                    <h6 className="text-secondary fs-12">School Fees </h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.school_fees
                                            ? "$" + employeeDetail?.school_fees
                                            : "--"}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6 className="text-secondary fs-12">
                                        Travelling/Petrol/Bus
                                    </h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.travelling
                                            ? "$" + employeeDetail?.travelling
                                            : "--"}
                                    </p>
                                </div>
                                <div>
                                    {" "}
                                    <h6 className="text-secondary fs-12">Entertainment/Video</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.entertainment
                                            ? "$" + employeeDetail?.entertainment
                                            : "--"}
                                    </p>
                                </div>

                                <div>
                                    <h6 className="text-secondary fs-12">
                                        Total Hire Purchase Payments{" "}
                                    </h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.hire_payments
                                            ? "$" + employeeDetail?.hire_payments
                                            : "--"}
                                    </p>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h6 className="text-secondary fs-12">
                                        Total Hire Purchase Payments
                                    </h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.hire_payments
                                            ? "$" + employeeDetail?.hire_payments
                                            : "--"}
                                    </p>
                                </div>
                                <div>
                                    {" "}
                                    <h6 className="text-secondary fs-12">Life Insurance</h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {employeeDetail?.life_insurance
                                            ? "$" + employeeDetail?.life_insurance
                                            : "--"}
                                    </p>
                                </div>

                                <div>
                                    <h6 className="text-secondary fs-12">
                                        {/* Total Hire Purchase Payments  */}
                                    </h6>
                                    <p style={{ width: "13rem", lineHeight: "inherit" }}>
                                        {/* $5886 */}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="card-body">
                            <h4 class="card-title text-primary">Documents</h4>
                            <div
                                className="main-contact-info-body"
                                style={{ marginTop: "0px" }}
                            >
                                <div className="media-list p-0">
                                    <div className="row row-cols-1 row-cols-md-2 g-4">
                                        <div className="col">
                                            <div
                                                className="card "
                                                style={{ border: "1px solid silver" }}
                                            >
                                                <div
                                                    className="card-body"
                                                    style={{ background: "#4239a4" }}
                                                >
                                                    <h3 className="card-title text-light ">Signature</h3>
                                                </div>
                                                {employeeDocs?.SIGNATURE?.map((Signature, index) => {
                                                    return (
                                                        <>
                                                            {Signature?.file_type === ".pdf" ||
                                                                Signature?.file_type === ".PDF" ||
                                                                Signature?.file_type === ".docx" ? (
                                                                <a href={Signature.doc_file} download>
                                                                    <img
                                                                        src="/assets/img/pdf.jpeg"
                                                                        style={{
                                                                            width: "70px",
                                                                            height: "100px",
                                                                            border: "1px solid silver",
                                                                        }}
                                                                        className="card-img-top m-1"
                                                                        alt="PDF"
                                                                    />
                                                                </a>
                                                            ) : (
                                                                <Image
                                                                    src={Signature ? Signature.doc_file : ""}
                                                                    style={{
                                                                        width: "70px",
                                                                        height: "100px",
                                                                        border: "1px solid silver",
                                                                    }}
                                                                    className="card-img-top m-1"
                                                                    alt="..."
                                                                />
                                                            )}{" "}
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div
                                                className="card  "
                                                style={{ border: "1px solid silver" }}
                                            >
                                                <div
                                                    className="card-body"
                                                    style={{ background: "#4239a4" }}
                                                >
                                                    <h3 className="card-title text-light ">Photo ID</h3>
                                                </div>
                                                {employeeDocs?.PHOTO_ID?.map((Photo, index) => {
                                                    return (
                                                        <>
                                                            {Photo?.file_type === ".pdf" ||
                                                                Photo?.file_type === ".PDF" ||
                                                                Photo?.file_type === ".docx" ? (
                                                                <a href={Photo.doc_file} download>
                                                                    <img
                                                                        src="/assets/img/pdf.jpeg"
                                                                        style={{
                                                                            width: "70px",
                                                                            height: "100px",
                                                                            border: "1px solid silver",
                                                                        }}
                                                                        className="card-img-top m-1"
                                                                        alt="PDF"
                                                                    />
                                                                </a>
                                                            ) : (
                                                                <Image
                                                                    src={Photo ? Photo.doc_file : ""}
                                                                    style={{
                                                                        width: "70px",
                                                                        height: "100px",
                                                                        border: "1px solid silver",
                                                                    }}
                                                                    className="card-img-top m-1"
                                                                    alt="..."
                                                                />
                                                            )}
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row row-cols-1 row-cols-md-2 g-4">
                                        <div className="col">
                                            <div
                                                className="card  "
                                                style={{ border: "1px solid silver" }}
                                            >
                                                <div
                                                    className="card-body"
                                                    style={{ background: "#4239a4" }}
                                                >
                                                    <h3 className="card-title text-light ">
                                                        Bank Statement
                                                    </h3>
                                                </div>
                                                {employeeDocs?.BANK_STATEMENTS?.map((Bank, index) => {
                                                    return (
                                                        <>
                                                            {Bank?.file_type === ".pdf" ||
                                                                Bank?.file_type === ".PDF" ||
                                                                Bank?.file_type === ".docx" ? (
                                                                <a href={Bank.doc_file} download>
                                                                    <img
                                                                        src="/assets/img/pdf.jpeg"
                                                                        style={{
                                                                            width: "70px",
                                                                            height: "100px",
                                                                            border: "1px solid silver",
                                                                        }}
                                                                        className="card-img-top m-1"
                                                                        alt="PDF"
                                                                    />
                                                                </a>
                                                            ) : (
                                                                <Image
                                                                    src={Bank ? Bank.doc_file : ""}
                                                                    style={{
                                                                        width: "70px",
                                                                        height: "100px",
                                                                        border: "1px solid silver",
                                                                    }}
                                                                    className="card-img-top m-1"
                                                                    alt="..."
                                                                />
                                                            )}
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div
                                                className="card"
                                                style={{ border: "1px solid silver" }}
                                            >
                                                <div
                                                    className="card-body"
                                                    style={{ background: "#4239a4" }}
                                                >
                                                    <h3 className="card-title text-light ">Pay Slip</h3>
                                                </div>
                                                {employeeDocs?.PAYSLIPS?.map((Pay, index) => {
                                                    return (
                                                        <>
                                                            {Pay?.file_type === ".pdf" ||
                                                                Pay?.file_type === ".PDF" ||
                                                                Pay?.file_type === ".docx" ? (
                                                                <a href={Pay.doc_file} download>
                                                                    <img
                                                                        src="/assets/img/pdf.jpeg"
                                                                        style={{
                                                                            width: "70px",
                                                                            height: "100px",
                                                                            border: "1px solid silver",
                                                                        }}
                                                                        className="card-img-top m-1"
                                                                        alt="PDF"
                                                                    />
                                                                </a>
                                                            ) : (
                                                                <Image
                                                                    src={Pay ? Pay.doc_file : ""}
                                                                    style={{
                                                                        width: "70px",
                                                                        height: "100px",
                                                                        border: "1px solid silver",
                                                                    }}
                                                                    className="card-img-top m-1"
                                                                    alt="..."
                                                                />
                                                            )}
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row row-cols-1 row-cols-md-2 g-4">
                                        <div className="col">
                                            <div
                                                className="card  "
                                                style={{ border: "1px solid silver" }}
                                            >
                                                <div
                                                    className="card-body"
                                                    style={{ background: "#4239a4" }}
                                                >
                                                    <h3 className="card-title text-light ">
                                                        Income Statement
                                                    </h3>
                                                </div>
                                                {employeeDocs?.INCOME?.map((Income, index) => {
                                                    return (
                                                        <>
                                                            {Income?.file_type === ".pdf" ||
                                                                Income?.file_type === ".PDF" ||
                                                                Income?.file_type === ".docx" ? (
                                                                <a href={Income.doc_file} download>
                                                                    <img
                                                                        src="/assets/img/pdf.jpeg"
                                                                        style={{
                                                                            width: "70px",
                                                                            height: "100px",
                                                                            border: "1px solid silver",
                                                                        }}
                                                                        className="card-img-top m-1"
                                                                        alt="PDF"
                                                                    />
                                                                </a>
                                                            ) : (
                                                                <Image
                                                                    src={Income ? Income.doc_file : ""}
                                                                    style={{
                                                                        width: "70px",
                                                                        height: "100px",
                                                                        border: "1px solid silver",
                                                                    }}
                                                                    className="card-img-top m-1"
                                                                    alt="..."
                                                                />
                                                            )}
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div
                                                className="card "
                                                style={{ border: "1px solid silver" }}
                                            >
                                                <div
                                                    className="card-body"
                                                    style={{ background: "#4239a4" }}
                                                >
                                                    <h3 className="card-title text-light ">
                                                        Loan Statement
                                                    </h3>
                                                </div>
                                                {employeeDocs?.LOAN_STATEMENTS?.map((Loan, index) => {
                                                    return (
                                                        <>
                                                            {Loan?.file_type === ".pdf" ||
                                                                Loan?.file_type === ".PDF" ||
                                                                Loan?.file_type === ".docx" ? (
                                                                <a href={Loan.doc_file} download>
                                                                    <img
                                                                        src="/assets/img/pdf.jpeg"
                                                                        style={{
                                                                            width: "70px",
                                                                            height: "100px",
                                                                            border: "1px solid silver",
                                                                        }}
                                                                        className="card-img-top m-1"
                                                                        alt="PDF"
                                                                    />
                                                                </a>
                                                            ) : (
                                                                <Image
                                                                    src={Loan ? Loan.doc_file : ""}
                                                                    style={{
                                                                        width: "70px",
                                                                        height: "100px",
                                                                        border: "1px solid silver",
                                                                    }}
                                                                    className="card-img-top m-1"
                                                                    alt="..."
                                                                />
                                                            )}
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row row-cols-1 row-cols-md-2 g-4">
                                        <div className="col">
                                            <div
                                                className="card  "
                                                style={{ border: "1px solid silver" }}
                                            >
                                                <div
                                                    className="card-body"
                                                    style={{ background: "#4239a4" }}
                                                >
                                                    <h3 className="card-title text-light ">Tin Number</h3>
                                                </div>
                                                {employeeDocs?.TIN_LETTER?.map((Tin, index) => {
                                                    return (
                                                        <>
                                                            {Tin?.file_type === ".pdf" ||
                                                                Tin?.file_type === ".PDF" ||
                                                                Tin?.file_type === ".docx" ? (
                                                                <a href={Tin.doc_file} download>
                                                                    <img
                                                                        src="/assets/img/pdf.jpeg"
                                                                        style={{
                                                                            width: "70px",
                                                                            height: "100px",
                                                                            border: "1px solid silver",
                                                                        }}
                                                                        className="card-img-top m-1"
                                                                        alt="PDF"
                                                                    />
                                                                </a>
                                                            ) : (
                                                                <Image
                                                                    src={Tin ? Tin.doc_file : ""}
                                                                    style={{
                                                                        width: "70px",
                                                                        height: "100px",
                                                                        border: "1px solid silver",
                                                                    }}
                                                                    className="card-img-top m-1"
                                                                    alt="..."
                                                                />
                                                            )}
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div
                                                className="card"
                                                style={{ border: "1px solid silver" }}
                                            >
                                                <div
                                                    className="card-body"
                                                    style={{ background: "#4239a4" }}
                                                >
                                                    <h3 className="card-title text-light ">
                                                        Employment Letter
                                                    </h3>
                                                </div>
                                                {employeeDocs?.EMPLOYMENT_LETTER?.map(
                                                    (Employ, index) => {
                                                        return (
                                                            <>
                                                                {Employ?.file_type === ".pdf" ||
                                                                    Employ?.file_type === ".PDF" ||
                                                                    Employ?.file_type === ".docx" ? (
                                                                    <a href={Employ.doc_file} download>
                                                                        <img
                                                                            src="/assets/img/pdf.jpeg"
                                                                            style={{
                                                                                width: "70px",
                                                                                height: "100px",
                                                                                border: "1px solid silver",
                                                                            }}
                                                                            className="card-img-top m-1"
                                                                            alt="PDF"
                                                                        />
                                                                    </a>
                                                                ) : (
                                                                    <Image
                                                                        src={Employ ? Employ.doc_file : ""}
                                                                        style={{
                                                                            width: "70px",
                                                                            height: "100px",
                                                                            border: "1px solid silver",
                                                                        }}
                                                                        className="card-img-top m-1"
                                                                        alt="..."
                                                                    />
                                                                )}
                                                            </>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row row-cols-1 row-cols-md-2 g-4">
                                        <div className="col">
                                            <div
                                                className="card  "
                                                style={{ border: "1px solid silver" }}
                                            >
                                                <div
                                                    className="card-body"
                                                    style={{ background: "#4239a4" }}
                                                >
                                                    <h3 className="card-title text-light ">
                                                        Company ID Certificate
                                                    </h3>
                                                </div>
                                                {employeeDocs?.COMPANY_ID_CERTIFICATE?.map(
                                                    (CompanyID, index) => {
                                                        return (
                                                            <>
                                                                {CompanyID?.file_type === ".pdf" ||
                                                                    CompanyID?.file_type === ".PDF" ||
                                                                    CompanyID?.file_type === ".docx" ? (
                                                                    <a href={CompanyID.doc_file} download>
                                                                        <img
                                                                            src="/assets/img/pdf.jpeg"
                                                                            style={{
                                                                                width: "70px",
                                                                                height: "100px",
                                                                                border: "1px solid silver",
                                                                            }}
                                                                            className="card-img-top m-1"
                                                                            alt="PDF"
                                                                        />
                                                                    </a>
                                                                ) : (
                                                                    <Image
                                                                        src={CompanyID ? CompanyID.doc_file : ""}
                                                                        style={{
                                                                            width: "70px",
                                                                            height: "100px",
                                                                            border: "1px solid silver",
                                                                        }}
                                                                        className="card-img-top m-1"
                                                                        alt="..."
                                                                    />
                                                                )}
                                                            </>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div
                                                className="card"
                                                style={{ border: "1px solid silver" }}
                                            >
                                                <div
                                                    className="card-body"
                                                    style={{ background: "#4239a4" }}
                                                >
                                                    <h3 className="card-title text-light ">
                                                        Vendor Invoice
                                                    </h3>
                                                </div>
                                                {employeeDocs?.VENDOR_INVOICE?.map((Vendor, index) => {
                                                    return (
                                                        <>
                                                            {Vendor?.file_type === ".pdf" ||
                                                                Vendor?.file_type === ".PDF" ||
                                                                Vendor?.file_type === ".docx" ? (
                                                                <a href={Vendor.doc_file} download>
                                                                    <img
                                                                        src="/assets/img/pdf.jpeg"
                                                                        style={{
                                                                            width: "70px",
                                                                            height: "100px",
                                                                            border: "1px solid silver",
                                                                        }}
                                                                        className="card-img-top m-1"
                                                                        alt="PDF"
                                                                    />
                                                                </a>
                                                            ) : (
                                                                <Image
                                                                    src={Vendor ? Vendor.doc_file : ""}
                                                                    style={{
                                                                        width: "70px",
                                                                        height: "100px",
                                                                        border: "1px solid silver",
                                                                    }}
                                                                    className="card-img-top m-1"
                                                                    alt="..."
                                                                />
                                                            )}
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-5">
                    <div
                        className="card"
                        style={{ overflow: "scroll", height: "133.5rem" }}
                    >
                        <div className="card-body">
                        {loader2 ? (
                <div className="d-flex">
                    <Loader height={50} stroke={6} />
                    <span style={{ color: "#4239a4", marginTop: "auto", marginLeft: "1rem" }}>
                        {" "} 2 minutes please ...
                    </span>
                </div>
            ) : (
                <>
                    {displayedHeadings.length > 0 ? (
                        <div>
                            {displayedHeadings.map((heading, index) => (
                                <div key={index} className="mb-2">
                                    <h5 style={{ color: "#0a1599" }}>
                                        {heading.key} <i className="fa fa-check" />
                                    </h5>
                                </div>
                            ))}
                        </div>
                    ) : (
                        analysisResponse.map((response, index) => (
                            <div key={index} className="mb-2">
                                <h5 style={{ color: "#0a1599" }}>{response.key} <i className="fa fa-check" /></h5>
                                <div>
                                    {response.lines.map((responseLines, lineIndex) => (
                                        <div key={lineIndex}>{responseLines}</div>
                                    ))}
                                </div>
                            </div>
                        ))
                    )}
                </>
            )}

                            {!status && (
                                <div>
                                    <span
                                        style={{
                                            color: "black",
                                            fontWeight: "bold",
                                            color: "#f1093e",
                                        }}
                                    >
                                        Looks like something went wrong
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Overallborrowerinformation;