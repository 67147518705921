
import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import Header from './layout/header'
import Footer from './layout/footer'
import Service from './../lenderService';
import Dashboard from './user/dashboard';
import Profile from './user/profile/profile';
import EditProfile from './user/profile/editProfile';
import ChangePassword from './user/profile/changePassword';
// import config from '../config.json';
import EmployeeDetail from './user//employee/employeeDetail';
import Employee from './user/employee/Employee';
import { UseAppContext } from '../Context/AppContext';
import Employerdetail from './user/ROI/Employerdetail';
import Pendingsettlement from './user/PayToMerchant/pendingsettlement';
import Usedlimitdetails from './user/employee/UserDetails/usedlimitdetails';
import Merchantdetail from './user/employee/UserDetails/merchantdetail';
import Addsettlement from './user/PayToMerchant/addsettlement';
import Thirdrocsettlement from './user/PayToThirdroc/thirdrocsettlement';
import Thirdroctransaction from './user/PayToThirdroc/thirdroctransaction';
import Merchanttransaction from './user/PayToMerchant/merchanttransaction';
import Newborrower from './user/employee/newborrower';
import Settlement from './user/EmployeeSettlement/settlement';
import EmployeeEmi from './user/EmployeeSettlement/EmployeeEmi';
import Employeetransaction from './user/PayToThirdroc/employeetransaction';
import Merchantpaidtxn from './user/PayToMerchant/merchantpaidtxn';
import Manageroles from './user/Roles&Permissions/Manageroles';
import Manageusers from './user/Roles&Permissions/Manageusers';
import Addrole from './user/Roles&Permissions/Addrole';
import Editroles from './user/Roles&Permissions/Editroles';
import Addusers from './user/Roles&Permissions/Addusers';
import Editusers from './user/Roles&Permissions/Editusers';
import ViewInvestment from './user/Manage Investment/ViewInvestment';
import Roi from './user/ROI/roi';
import EmployeeSetLimit from './user/employee/EmployeeSetLimit';
import Emibyorderid from './user/PayToMerchant/Emibyorderid'
import SampleText from './SampleFolder/SampleText';
import Disbursedemi from './Loan/Disbursedemi';
import ManagePin from './user/managePin/managePin';
import ManageEwaAdvance from './Loan/manageEwaAdvance';
import AddewaAdvance from './Loan/AddewaAdvance';
import PendingRepayment from './user/Repayment/pendingRepayment';
import PaidRepayment from './user/Repayment/paidRepayment';
import EWArepayment from './Loan/EWArepayment';
import Reconciliation from './Loan/reconciliation';
import Overallborrowerinformation from './Loan/overallborrowerinformation';
import Applicationoverview from './Loan/Applicationoverview';

const api = new Service();
const Index = (props) => {

    const prefix = process.env.REACT_APP_LENDER_PRIFIX;
    const { profile_id, user_id, file_id, caseStatus, customer_id, loan_id, loan_type, dsa_id, lender_id, id, status, type } = useParams();
    const navigate = useNavigate();
    const { header } = UseAppContext();
    const [locationName, setlocationName] = useState(prefix + '/dashboard');
    const location = useLocation();

    useEffect(() => {
        setlocationName(location.pathname)
    }, [location.pathname])


    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            <div className="page">
                <div className="page-main">
                    <Header prefix={prefix} profile_id={profile_id} caseStatus={caseStatus} dsa_id={dsa_id} lender_id={lender_id} user_id={user_id} />
                    <div className={header ? "app_content_res app-content main-content" : "app-content main-content"}>
                        <div className="side-app">
                            {locationName == prefix + '/dashboard' || locationName == prefix ?
                                <Dashboard api={api} toast={toast} />
                                : locationName == prefix + '/profile' ?
                                    <Profile api={api} toast={toast} prefix={prefix} />
                                    : locationName == prefix + '/editprofile/' + user_id ?
                                        <EditProfile api={api} toast={toast} prefix={prefix} user_id={user_id} />
                                        : locationName == prefix + '/changepassword/' + id ?
                                            <ChangePassword api={api} toast={toast} prefix={prefix} />
                                            : locationName == prefix + '/employee' ?
                                                <Employee api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                : locationName == prefix + '/employee_detail/' + id + "/" + type || locationName == prefix + '/case_detail/' + id ?
                                                    <EmployeeDetail api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                    : locationName == prefix + '/merchant_detail/' + id ?
                                                        <Merchantdetail api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                        : locationName == prefix + '/employee_setlimit/' + id + "/" + type ?
                                                            <EmployeeSetLimit api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                            : locationName == prefix + '/pending_repayment' ?
                                                                <PendingRepayment api={api} toast={toast} prefix={prefix} />
                                                                : locationName == prefix + '/roi' ?
                                                                    <Roi api={api} toast={toast} prefix={prefix} />
                                                                    : locationName == prefix + '/employer_detail/' + id + "/" + type ?
                                                                        <Employerdetail api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                        : locationName == prefix + '/usedlimitdetails/' + id + "/" + type ?
                                                                            <Usedlimitdetails api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                            : locationName == prefix + '/pendingsettlement' ?
                                                                                <Pendingsettlement api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} />
                                                                                : locationName == prefix + '/addsettlement/' + id ?
                                                                                    <Addsettlement api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                    : locationName == prefix + '/thirdrocsettlement' ?
                                                                                        <Thirdrocsettlement api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                        : locationName == prefix + '/thirdroctransaction' ?
                                                                                            <Thirdroctransaction api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                            : locationName == prefix + '/merchanttransaction' ?
                                                                                                <Merchanttransaction api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                                : locationName == prefix + '/paid_repayment' ?
                                                                                                    <PaidRepayment api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                                    : locationName == prefix + '/newborrower' ?
                                                                                                        <Newborrower api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                                        : locationName == prefix + '/settlement' ?
                                                                                                            <Settlement api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                                            : locationName == prefix + '/employeeEmi/' + id ?
                                                                                                                <EmployeeEmi api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                                                : locationName == prefix + '/employee_transaction/' + id ?
                                                                                                                    <Employeetransaction api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                                                    : locationName == prefix + '/merchant_paid_txn/' + id ?
                                                                                                                        <Merchantpaidtxn api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                                                        : locationName == prefix + '/manage_role' ?
                                                                                                                            <Manageroles api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                                                            : locationName == prefix + '/manage_user' ?
                                                                                                                                <Manageusers api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                                                                : locationName == prefix + '/add_role' ?
                                                                                                                                    <Addrole api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                                                                    : locationName == prefix + '/edit_roles/' + id ?
                                                                                                                                        <Editroles api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                                                                        : locationName == prefix + '/add_users' ?
                                                                                                                                            <Addusers api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                                                                            : locationName == prefix + '/edit_users/' + id ?
                                                                                                                                                <Editusers api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                                                                                : locationName == prefix + '/manage_ewa_advance' ?
                                                                                                                                                    <ManageEwaAdvance api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                                                                                    : locationName == prefix + '/add_ewa_advance' ?
                                                                                                                                                        <AddewaAdvance api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                                                                                        // : locationName == prefix + '/case_detail/' + id ?
                                                                                                                                                        //     <Casedetails api={api} toast={toast}  prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                                                                                        // : locationName == prefix + '/manage_status' ?
                                                                                                                                                        //     <Managestatus api={api} toast={toast}  prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                                                                                        : locationName == prefix + '/disbursed_repayment/' + id || locationName == prefix + '/revise_disbursed_repayment/' + id ?
                                                                                                                                                            <Disbursedemi api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                                                                                            : locationName == prefix + '/view_investment' ?
                                                                                                                                                                <ViewInvestment api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                                                                                                : locationName == prefix + '/emibyorderid/' + id ?
                                                                                                                                                                    <Emibyorderid api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                                                                                                    : locationName == prefix + '/manage_pin/' + id ?
                                                                                                                                                                        <ManagePin api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                                                                                                        : locationName == prefix + '/ewa_repayment' ?
                                                                                                                                                                            <EWArepayment api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                                                                                                            : locationName == prefix + '/reconciliation' ?
                                                                                                                                                                                <Reconciliation api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                                                                                                                : locationName == prefix + '/application_agent' ?
                                                                                                                                                                                    <Applicationoverview api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                                                                                                                    : locationName == prefix + '/overall_borrower_information/' + id ?
                                                                                                                                                                                        <Overallborrowerinformation api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                                                                                                                        : locationName == prefix + '/sample_text' ?
                                                                                                                                                                                            <SampleText api={api} toast={toast} prefix={prefix} caseStatus={caseStatus} lender_id={lender_id} />
                                                                                                                                                                                            : ''}
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        </>

    )



}

export default Index