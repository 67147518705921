// import React, { useState } from 'react'
// import StarRatings from 'react-star-ratings';

// const FeedbackModal = ({ modalStatus, setModalStatus }) => {
//     // console.log(modalStatus, "==========")
//     const [selectConsent, setSelectConsent] = useState('')
//     const [selectConsent1, setSelectConsent1] = useState('')

//     const [selectedScore, setSelectedScore] = useState([
//         {
//             key: "credit_score_outcome", value: 0
//         },
//         {
//             key: "bank_statement_analysis", value: 0
//         },
//         {
//             key: "payslip_salary_analysis", value: 0
//         },
//         {
//             key: "risk_indicator_analysis", value: 0
//         },
//         {
//             key: "behavioral_analysis", value: 0
//         },
//         {
//             key: "loan_eligibility_calculation", value: 0
//         },
//         {
//             key: "conclusion", value: 0
//         },
//         {
//             key: "overall_report", value: 0
//         }
//     ]);


//     const handleScoreChange = (newRating, name) => {
//         let updatedRating = selectedScore.map((data) => {
//             if (data.key == name) {
//                 data.value = newRating
//             }
//             return data
//         })
//         setSelectedScore(updatedRating)
//     };

//     const handleCheckboxChange = (e, type) => {
//         if (type === "decision") {
//             setSelectConsent1(e.target.value)
//         } else if (type === "analysis") {
//             setSelectConsent(e.target.value)

//         }
//     }
//     return (
//         <>
//             {modalStatus &&
//                 <>
//                     <div className="modal fade show" style={{ display: 'block', boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)' }} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
//                         <div className="modal-dialog modal-lg">
//                             <div className="modal-content">
//                                 <div className="modal-header d-flex justify-content-between">
//                                     <div>
//                                         <h5 className="modal-title" id="staticBackdropLabel">Feedback</h5>
//                                     </div>
//                                     <div>
//                                         <button type="button" className="btn btn-dark me-2" data-bs-dismiss="modal" onClick={() => setModalStatus(false)}>Close</button>
//                                         <button type="button" className="btn btn-primary">Understood</button>
//                                     </div>
//                                 </div>
//                                 <div className="modal-body">
//                                     <div className="row">
//                                         {selectedScore.map((Items, index) => {
//                                             return (
//                                                 <>
//                                                     <div className="col-md-6">
//                                                         <div className="mb-3 d-flex justify-content-between">
//                                                             <div>
//                                                                 <label key={index} className="me-2">{Items.key.replaceAll('_', " ")} </label>
//                                                             </div>
//                                                             <div>
//                                                                 <StarRatings
//                                                                     rating={Items.value}
//                                                                     starRatedColor="#0f2f82"
//                                                                     changeRating={handleScoreChange}
//                                                                     numberOfStars={5}
//                                                                     starDimension={"25px"}
//                                                                     name={Items.key}
//                                                                 />
//                                                             </div>
//                                                         </div>
//                                                     </div>
//                                                 </>
//                                             )
//                                         })}

//                                     </div>
//                                     <div className="row">
//                                         <div className="col-md-6 col-sm-12">
//                                             <div className="form-field">
//                                                 <label>discrepancies or missing details </label><br />
//                                                 <textarea placeholder="Please write here..." style={{ minHeight: '5rem' }} className="form-control" />
//                                             </div>
//                                         </div>
//                                         <div className="col-md-6 col-sm-12">
//                                             <div className="form-field">
//                                                 <label>How can this report be improved? </label><br />
//                                                 <textarea placeholder="Please write here..." style={{ height: '5rem' }} class="form-control" id="message" name="message" />
//                                             </div>
//                                         </div>
//                                         <div className="col-md-6 col-sm-12">
//                                             <label>Was the analysis accurate?
//                                                 <div className="row mb-3 d-flex justify-content-around">
//                                                     <div className=" mt-1">
//                                                         <input
//                                                             className=""
//                                                             type="checkbox"
//                                                             value="1"
//                                                             checked={selectConsent === '1'}
//                                                             onChange={(e) => handleCheckboxChange(e, "analysis")}
//                                                         />
//                                                         <label className="form-check-label ms-3" style={{ fontSize: 'medium' }}>Yes</label>
//                                                         <input
//                                                             className="ms-3"
//                                                             type="checkbox"
//                                                             value="0"
//                                                             checked={selectConsent === '0'}
//                                                             onChange={(e) => handleCheckboxChange(e, "analysis")}
//                                                         />
//                                                         <label className="form-check-label ms-3 fs-16" style={{ fontSize: 'medium' }}>No</label>
//                                                     </div>
//                                                 </div>
//                                             </label>
//                                         </div>
//                                         <div className="col-md-6 col-sm-12">
//                                             <label>Was the analysis useful for decision-making?
//                                                 <div className="row mb-3 d-flex justify-content-around">
//                                                     <div className=" mt-1">
//                                                         <input
//                                                             className=""
//                                                             type="checkbox"
//                                                             value="1"
//                                                             checked={selectConsent1 === '1'}
//                                                             onChange={(e) => handleCheckboxChange(e, "decision")}
//                                                         />
//                                                         <label className="form-check-label ms-3" style={{ fontSize: 'medium' }}>Yes</label>
//                                                         <input
//                                                             className="ms-3"
//                                                             type="checkbox"
//                                                             value="0"
//                                                             checked={selectConsent1 === '0'}
//                                                             onChange={(e) => handleCheckboxChange(e, "decision")}
//                                                         />
//                                                         <label className="form-check-label ms-3 fs-16" style={{ fontSize: 'medium' }}>No</label>
//                                                     </div>
//                                                 </div>
//                                             </label>
//                                         </div>
//                                     </div>
//                                 </div>
//                                 {/* <div className="modal-footer">

//                             </div> */}
//                             </div>
//                         </div>

//                     </div>
//                     <div className="modal-backdrop fade show" />
//                 </>
//             }
//         </>
//     )
// }

// export default FeedbackModal


import { useFormik } from "formik";
import * as yup from "yup";
import { useState } from "react";
import StarRatings from "react-star-ratings";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { postApi } from "../service/lenderService";

const FeedbackModal = ({ modalStatus, setModalStatus }) => {
    const param = useParams()
    const [viewErrorMessage, setViewErrorMessage] = useState('')
    const [selectedScore, setSelectedScore] = useState([
        { key: "credit_score_outcome", value: 0 },
        { key: "bank_statement_analysis", value: 0 },
        { key: "payslip_salary_analysis", value: 0 },
        { key: "risk_indicator_analysis", value: 0 },
        { key: "behavioral_analysis", value: 0 },
        { key: "loan_eligibility_calculation", value: 0 },
        { key: "conclusion", value: 0 },
        { key: "overall_report", value: 0 },
    ]);


    // Formik setup
    const formik = useFormik({
        initialValues: {
            discrepancies: "",
            improvements: "",
            analysisAccurate: false,
            analysisUseful: false,
        },
        validationSchema: yup.object({
            discrepancies: yup.string().required("This field is required"),
            improvements: yup.string().required("This field is required"),
        }),
        onSubmit: async (values) => {
            const FilterData = selectedScore.filter((items) => items.value == "0")
            if (FilterData.length > 0) {
                setViewErrorMessage("Please Select All fields")
            } else {
                const response = await postApi("/submit-ml-feedback", {
                    user_loan_id: param?.id,
                    was_the_analysis_accurate: values.analysisAccurate == false ? "0" : "1",
                    was_the_analysis_useful_for_decision_making: values.analysisUseful == false ? "0" : "1",
                    discrepancies_or_missing_details: values.discrepancies,
                    how_can_this_report_be_improved: values.improvements,
                    selectedScore: selectedScore
                })
                if (response.status) {
                    toast.success(response.message)
                    setModalStatus(false)
                    formik.resetForm();
                } else {
                    toast.error(response.status)
                }
            }
        },
    });

    // const handleScoreChange = (newRating, name) => {
    //     setSelectedScore((prevScores) =>
    //         prevScores.map((item) =>
    //             item.key === name ? { ...item, value: newRating } : item
    //         )
    //     );
    // };

    const handleScoreChange = (newRating, name) => {
        setSelectedScore((prevScores) => {
            const updatedScores = prevScores.map((item) =>
                item.key === name ? { ...item, value: newRating } : item
            );
            const allValuesGreaterThanZero = updatedScores.every(item => item.value > 0);
            if (allValuesGreaterThanZero) {
                setViewErrorMessage("");
            }
            return updatedScores;
        });
    };


    return (
        <>
            {modalStatus &&

                <div className="modal fade show" style={{ display: 'block', boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)' }} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="modal-content">
                                <div className="modal-header d-flex justify-content-between">
                                    <h5 className="modal-title">Feedback</h5>
                                    <div>
                                        <button type="button" className="btn btn-dark me-2" data-bs-dismiss="modal" onClick={() => setModalStatus(false)}>Close</button>
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </div>

                                <div className="modal-body">
                                    <div className="row">
                                        {selectedScore.map((item, index) => (
                                            <div key={index} className="col-md-6">
                                                <div className="mb-3 d-flex justify-content-between">
                                                    <label>{item.key.replaceAll("_", " ")}</label>
                                                    <StarRatings
                                                        rating={item.value}
                                                        starRatedColor="#0f2f82"
                                                        changeRating={(rating) => handleScoreChange(rating, item.key)}
                                                        numberOfStars={5}
                                                        starDimension="25px"
                                                        name={item.key}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                        {viewErrorMessage !== "" && <div className="text-danger fs-14 text-center">{viewErrorMessage}</div>}

                                    </div>

                                    <div className="row">
                                        {/* Discrepancies Field */}
                                        <div className="col-md-6 col-sm-12">
                                            <div className="form-field">
                                                <label>Discrepancies or missing details</label>
                                                <textarea
                                                    name="discrepancies"
                                                    placeholder="Please write here..."
                                                    style={{ minHeight: "5rem" }}
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.discrepancies}
                                                />
                                                {formik.touched.discrepancies && formik.errors.discrepancies ? (
                                                    <div className="text-danger fs-12">{formik.errors.discrepancies}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {/* Improvements Field */}
                                        <div className="col-md-6 col-sm-12">
                                            <div className="form-field">
                                                <label>How can this report be improved?</label>
                                                <textarea
                                                    name="improvements"
                                                    placeholder="Please write here..."
                                                    style={{ height: "5rem" }}
                                                    className="form-control"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.improvements}
                                                />
                                                {formik.touched.improvements && formik.errors.improvements ? (
                                                    <div className="text-danger fs-12">{formik.errors.improvements}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        {/* Analysis Accurate Checkbox */}
                                        <div className="col-md-6 col-sm-12">
                                            <label>Was the analysis accurate?</label>
                                            <div className="row mb-3 d-flex justify-content-around">
                                                <div className="mt-1">
                                                    <input
                                                        type="checkbox"
                                                        checked={formik.values.analysisAccurate === true}
                                                        onChange={() => formik.setFieldValue("analysisAccurate", true)}
                                                    />
                                                    <label className="form-check-label ms-3">Yes</label>

                                                    <input
                                                        type="checkbox"
                                                        className="ms-3"
                                                        checked={formik.values.analysisAccurate === false}
                                                        onChange={() => formik.setFieldValue("analysisAccurate", false)}
                                                    />
                                                    <label className="form-check-label ms-3">No</label>
                                                </div>
                                                {formik.touched.analysisAccurate && formik.errors.analysisAccurate ? (
                                                    <div className="text-danger fs-12">{formik.errors.analysisAccurate}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        {/* Analysis Useful Checkbox */}
                                        <div className="col-md-6 col-sm-12">
                                            <label>Was the analysis useful for decision-making?</label>
                                            <div className="row mb-3 d-flex justify-content-around">
                                                <div className="mt-1">

                                                    <input
                                                        type="checkbox"
                                                        checked={formik.values.analysisUseful === true}
                                                        onChange={() => formik.setFieldValue("analysisUseful", true)}
                                                    />
                                                    <label className="form-check-label ms-3">Yes</label>

                                                    <input
                                                        type="checkbox"
                                                        className="ms-3"
                                                        checked={formik.values.analysisUseful === false}
                                                        onChange={() => formik.setFieldValue("analysisUseful", false)}
                                                    />
                                                    <label className="form-check-label ms-3">No</label>
                                                </div>
                                                {formik.touched.analysisUseful && formik.errors.analysisUseful ? (
                                                    <div className="text-danger fs-12">{formik.errors.analysisUseful}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            }
        </>
    );
};

export default FeedbackModal;
