import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import toast from 'react-hot-toast'
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as XLSX from 'xlsx';
import { getApi, postApi } from '../service/lenderService';
import Search from '../../components/Search/Search';
import Loader from '../../components/Loader/loader';
import PaginationComponent from '../../PaginationComponent';
import { Image } from 'antd';

const Reconciliation = () => {
    const closeButton = useRef()
    const closeMatchingRecordModal = useRef()
    const openSeconModal = useRef()
    const [loader, setloader] = useState(true)
    const [ValidPagination, setValidPagination] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    const [dataArray, setDataArray] = useState([])
    const [matchRecords, setMatchRecords] = useState([])
    const [checkedEmiIds, setCheckedEmiIds] = useState([])
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [search, setSearch] = useState('');
    const [sortedBy, setSortedBy] = useState("created_at")
    const [orderBy, setOrderBy] = useState('desc')
    const [uploadFile, setUploadFile] = useState(null)
    const inputref = useRef()
    const [handleStatus, setHandleStatus] = useState(false)
    const [btnLoading, setBtnLoading] = useState(false)

    const viewReconciliationDetail = async () => {
        try {
            const response = await getApi('/due-ewa-advance-emi', { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy })
            if (response.status) {
                setTimeout(() => {
                    setDataArray(response?.data?.rows);
                    setTotalPagesCount(response?.data?.count)
                    setloader(false)
                }, 2000);

                setloader(true)
            } else {
                console.log(response.message)
                toast.error(response.message)
            }

        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        viewReconciliationDetail()
    }, [search, pagesCount, sortedBy, orderBy])

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const HandleReminderFile = (e) => {
        const pdf = e.target.files[0];
        if (pdf && (pdf?.type == 'text/csv' || pdf?.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
            setUploadFile(pdf);
            // readExcel(pdf)
            setHandleStatus(true)
        }
        else {
            toast.error('Invalid file type. Please upload a valid Excel or CSV file.');
            e.target.value = '';
            setUploadFile(null);

            return;
        }
    }

    const AddReminder = useFormik({
        initialValues: {
            isExcelFile: false,
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            // reminderMessage: yup.string().required('Please Enter Message'),

        }),
        onSubmit: async (values) => {
            if (uploadFile) {

                const formData = new FormData();
                formData.append("file", uploadFile);

                const response = await postApi("/bank-statement-compare", formData, true);
                console.log("🚀 ~ onSubmit: ~ response:", response?.data?.row)

                if (response.status == true) {

                    setMatchRecords(response?.data?.row)

                    // toast.success(response.message);
                    AddReminder.setFieldValue("isExcelFile", "")
                    closeButton.current.click()
                    // setExcelFile(null)   
                    openSeconModal.current.click()
                    inputref.current.value = ""
                    AddReminder.resetForm()
                    setHandleStatus(false)
                } else {
                    inputref.current.value = ""
                    toast.error(response.message);
                }

            }

        },
    })

    const handleCheckClick = (emiId, mainObj) => {
        const filterArray = checkedEmiIds.filter((item) =>
            item.emiId == emiId
            && item["Transaction ID"] == mainObj["Transaction ID"]
            && item["Transaction Date"] == mainObj["Transaction Date"]
            && item["Description"] == mainObj["Description"]
            && item["Transaction ID"] == mainObj["Transaction ID"]
        )

        if (filterArray.length == 0) {
            setCheckedEmiIds((prev) =>
                [
                    ...prev,
                    {
                        emiId: emiId,
                        "Transaction ID": mainObj["Transaction ID"],
                        "Transaction Date": mainObj["Transaction Date"],
                        "Description": mainObj["Description"],
                        "Credit Amount(FJD)": mainObj["Credit Amount(FJD)"],
                    }
                ]
            )
        } else {
            const remainingData = checkedEmiIds.filter((item) =>
                !(item.emiId == emiId
                    && item["Transaction ID"] == mainObj["Transaction ID"]
                    && item["Transaction Date"] == mainObj["Transaction Date"]
                    && item["Description"] == mainObj["Description"]
                    && item["Transaction ID"] == mainObj["Transaction ID"]
                )
            )
            setCheckedEmiIds(remainingData)
        }
    }

    const handleMarkEmiPaid = async () => {
        try {
            setBtnLoading(true)

            const response = await postApi("/mark-emi-paid", { emiArray: checkedEmiIds });

            if (response.status == true) {

                setCheckedEmiIds([])
                setBtnLoading(false)
                toast.success(response.message);

                viewReconciliationDetail()

                closeButton?.current?.click()
                closeMatchingRecordModal?.current?.click()
            } else {
                toast.error(response.message);
                setBtnLoading(false)
            }

        } catch (error) {
            console.log("🚀 ~ handleMarkEmiPaid ~ error:", error)
            setBtnLoading(false)
        }
    }

    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header mb-3">
                <div className="page-leftheader">
                    {/* <h4 className="page-title mb-0 text-primary">Paid</h4> */}

                    <h4 className="page-title mb-0 text-primary ">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a>EWA Advance</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Reconciliation</li>
                            </ol>
                        </nav>
                    </h4>
                </div>
                <div className="page-rightheader">
                    <button className='btn btn-primary me-2' data-bs-toggle="modal" data-bs-target="#staticBackdropmatch">Reconcile</button>
                    <div class="modal fade" id="staticBackdropmatch" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-md">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h1 class="modal-title fs-5" id="staticBackdropLabel">Verify Document</h1>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <form onSubmit={AddReminder.handleSubmit}>
                                        {/* <input type='file'></input> */}
                                        <div className="page-rightheader"/*  style={{ marginLeft: '10px' }} */>
                                            <div className="btn-list ">
                                                <label>Add File: {/* <span>Note</span> */}</label>
                                                <button className="btn btn-outline-primary uploadbmodal">
                                                    <label htmlFor="formFile">
                                                        <i className="fa fa-upload fa-beat me-2"></i>
                                                    </label>
                                                    <input ref={inputref} type="file" id='formFile' onChange={(e) => HandleReminderFile(e)} />
                                                </button>
                                                {AddReminder.touched.reminderMessage && AddReminder.errors.reminderMessage ?
                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddReminder.errors.reminderMessage}</div> : ''}
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" ref={closeButton} class="btn btn-dark" data-bs-dismiss="modal">Close</button>
                                            <button type="submit" class="btn btn-primary" disabled={!handleStatus} >Upload</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-12" />
                <div className="col-xl-4 col-lg-6 col-md-12" />
                <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card visiableCard">
                        <div className="card-body p-0 visiableCard">
                            <div className="table-responsive">
                                <table className="table table-hover card-table table-vcenter text-nowrap" /* ref={tableRef} */>
                                    <thead className="border-bottom-0 pt-3 pb-3">
                                        <tr>
                                            {/* <th><input type="checkbox" name="id"
                                                checked={isChecked}
                                                readOnly
                                                onClick={() => handleCheckClick()}
                                            /></th> */}
                                            <th className="font-weight-bold">EMI ID</th>
                                            <th className="font-weight-bold">Customer Details  {/* {sortedBy == "customer_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('customer_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('customer_name') }}> </i>} */} </th>
                                            <th className="font-weight-bold">Update By Borrower {/* {sortedBy == "merchant_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }}> </i>}  */}</th>
                                            <th className="font-weight-bold">Amount to be Paid {/* {sortedBy == "txn_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_amount') }}> </i>}  */}</th>
                                            <th className="font-weight-bold">Description </th>
                                            <th className="font-weight-bold">Status </th>
                                            <th className="font-weight-bold">Attachment </th>
                                            <th className="font-weight-bold">Due Date </th>
                                        </tr>
                                    </thead>
                                    {loader == true ? <tbody>
                                        <tr><td /><td /> <td />
                                            <Loader /></tr>
                                    </tbody> :
                                        <>
                                            <tbody>
                                                {dataArray?.length > 0 &&
                                                    dataArray.map((item) => {
                                                        return (
                                                            <tr>
                                                                {/* <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        name={110}
                                                                        checked={checheked(110) === 1 ? true : false} onChange={(event) => selectTransactions(event, 110)}
                                                                    />
                                                                </td> */}
                                                                <td><span className="font-weight-normal1"> {item.id} </span></td>
                                                                <td><span className="font-weight-normal1" ><span className="font-weight-normal1">{item?.loanApplication?.first_name} {item?.loanApplication?.last_name}<br />{item?.loanApplication?.mobile_number}</span></span></td>
                                                                <td><span className="font-weight-normal1"> {item?.user_entered_amount ? "$" + item.user_entered_amount : "--"}</span></td>
                                                                <td><span className="font-weight-normal1 text-danger"> {item?.amount ? "$" + item.amount : "--"}</span></td>
                                                                <td><span className="font-weight-normal1 tableDescriptionWidth">{item?.description ? item.description : "--"}</span></td>
                                                                <td><span className="font-weight-normal1 text-warning"> {item?.status ? item.status.replaceAll("_", " ") : "--"}</span></td>
                                                                <td>{item?.emi_docs ? <Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={item?.emi_docs} alt='' /> : "--"}</td>
                                                                <td><span className="font-weight-normal1">{item?.due_date}</span></td>

                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                            <tbody>
                                                {dataArray?.length == 0 &&
                                                    <tr>
                                                        <td colSpan={10} className='text-center'>No Data Available</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </>}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                {pagesCount > 0 && dataArray?.length > 0 && ValidPagination === true ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems=/* {totalPagesCount} */ "1"
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}
                {pagesCount > 0 && dataArray?.length > 0 && ValidPagination === false ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}
            </div>
            <button type="button" ref={openSeconModal} style={{ display: 'none' }} data-bs-toggle="modal" data-bs-target="#staticBackdrop"></button>

            {/* ******************************************************** */}
            {/* ********** MATCHING RECORDS MODEL ********************** */}
            {/* ******************************************************** */}
            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Updated Matching Data</h1>
                            {/* <input className='form-control w-30 mt-1' placeholder='-- Search here--'></input> */}
                            {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            <button ref={closeMatchingRecordModal} type="button" class="btn btn-outline-primary" data-bs-dismiss="modal"><i className='fas fa-close'></i></button>

                        </div>
                        <div class="modal-body">
                            <table className="table table-hover card-table table-vcenter text-nowrap" /* ref={tableRef} */>
                                <thead className="border-bottom-0 pt-3 pb-3">
                                    <tr>
                                        <th className="font-weight-bold">Transaction ID{/* {sortedBy == "merchant_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }}> </i>}  */}</th>
                                        <th className="font-weight-bold">Description {/* {sortedBy == "customer_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('customer_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('customer_name') }}> </i>} */} </th>
                                        <th className="font-weight-bold">Credit Amount(FJD) {/* {sortedBy == "txn_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_amount') }}> </i>}  */}</th>
                                        <th className="font-weight-bold">Transaction Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {matchRecords && matchRecords?.length > 0 &&
                                        matchRecords.map((item) => {
                                            return (
                                                <>
                                                    <tr style={{ backgroundColor: "lightskyblue" }}>
                                                        <td><span className="font-weight-normal1"> {item["Transaction ID"]} </span></td>
                                                        <td><span className="font-weight-normal1"> {item["Description"]} </span></td>
                                                        <td><span className="font-weight-normal1"> ${item["Credit Amount(FJD)"]} </span></td>
                                                        <td><span className="font-weight-normal1"> {item["Transaction Date"]} </span></td>
                                                    </tr>
                                                    <tr >
                                                        <td colSpan={5}>
                                                            <div className="sub-table-container">
                                                                <table className="table table-hover card-table table-vcenter text-nowrap">
                                                                    <thead className="border-bottom-0 pt-3 pb-3">
                                                                        <tr style={{ background: '#b6b7cc' }}>
                                                                            {/* <th><input type="checkbox" name="id" checked={isChecked} readOnly onClick={() => handleCheckClick()} /></th> */}
                                                                            <th></th>
                                                                            <th className="font-weight-bold">EMI ID</th>
                                                                            <th className="font-weight-bold">Customer Details  {/* {sortedBy == "customer_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('customer_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('customer_name') }}> </i>} */} </th>
                                                                            <th className="font-weight-bold">Update By Borrower {/* {sortedBy == "merchant_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }}> </i>}  */}</th>
                                                                            <th className="font-weight-bold">Amount to be Paid {/* {sortedBy == "txn_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_amount') }}> </i>}  */}</th>
                                                                            <th className="font-weight-bold">Description </th>
                                                                            <th>Attachment</th>
                                                                            <th className="font-weight-bold">Due Date </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {item?.records?.length > 0 && item?.records?.map((subItem, index) => {

                                                                            const isChecked = checkedEmiIds.filter((data) =>
                                                                                data.emiId == subItem.id
                                                                                && data["Transaction ID"] == item["Transaction ID"]
                                                                                && data["Transaction Date"] == item["Transaction Date"]
                                                                                && data["Description"] == item["Description"]
                                                                                && data["Transaction ID"] == item["Transaction ID"]
                                                                            )?.length == 1 ? true : false

                                                                            return (
                                                                                <tr key={index}>
                                                                                    <th><input type="checkbox" name="id" checked={isChecked} readOnly onClick={() => handleCheckClick(subItem.id, item)} /></th>
                                                                                    <td><span className="font-weight-normal1"> {subItem.id} </span></td>
                                                                                    <td><span className="font-weight-normal1" ><span className="font-weight-normal1">{subItem?.first_name} {subItem?.last_name}<br />{subItem?.mobile_number}</span></span></td>
                                                                                    <td><span className="font-weight-normal1"> {subItem?.user_entered_amount ? "$" + subItem?.user_entered_amount : "--"} </span></td>
                                                                                    <td><span className="font-weight-normal1 text-danger"> ${subItem?.amount}</span></td>
                                                                                    <td><span className="font-weight-normal1 tableDescriptionWidth"> {subItem?.description ? subItem?.description : "--"}</span></td>
                                                                                    <td>{subItem?.emi_docs ? <Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={subItem?.emi_docs} alt='' /> : "--"}</td>
                                                                                    {/* <td><Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={process.env.PUBLIC_URL + '/assets/img/Fintranxect-Logo-1.png'} alt='' /></td> */}
                                                                                    <td><span className="font-weight-normal1">{subItem?.due_date}</span></td>
                                                                                </tr>
                                                                            )
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            {matchRecords?.length == 0 &&
                                <div className='d-flex justify-content-center font-weight-bold'>No Matching Records Found.</div>
                            }
                        </div>
                        <div class="modal-footer">
                            {/* <button type="button" class="btn btn-dark" data-bs-dismiss="modal">Close</button> */}
                            {btnLoading
                                ?
                                <div className='me-4'>
                                    <Loader height={30} stroke={6} />
                                </div>
                                : <button
                                    type="button"
                                    disabled={checkedEmiIds.length == 0}
                                    className="btn btn-primary"
                                    onClick={() => handleMarkEmiPaid()}
                                >Mark as received
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reconciliation