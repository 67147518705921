
import Header from './header';
import Footer from './footer';
const DigitalAutomation = () => {


    return (
        <>
            <Header />
            <div className="intro-four bgimage">
                <div className="bg_image_holder" style={{ backgroundImage: "url('./website/assets/img/slider_bg4.jpg')", opacity: 1 }}>
                    <img src="./website/assets/img/slider_bg4.jpg" alt="slider_bg4" /></div>
                <div className="intro-four--contents content_above">
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-lg-7">
                                <h1 className="display-3 mb-1">Digital Lending</h1>
                                <p className="mb-2">We provide web apps &amp; mobile apps with highly intuitive user interfaces that helps our target customers to simply focus on servicing their customers and not worry about tech</p>
                                <a href="./website/assets/img/pdf/Thirdrocs-Digital-Lending-Platform_website.pdf" className="bnplPage brNone"><i className="fa-regular fa-file-pdf"></i> Digital Lending, Learn More</a>
                            </div> */}

                            <div className="col-lg-7">
                                <h1 className="display-3 mb-1">Digital Lending</h1>
                                <ul className="flex-column" style={{ listStyle: "disc", marginTop: '1rem' }}>
                                    <li className="mb-2">Automate your credit & receivables process</li>
                                    <li className="mb-2">Do fully automated processing in just a few minutes instead of days</li>
                                    <li className="mb-2">We handle every type of creditors-debtors scenario across industries</li>
                                    <li className="mb-2">Vehicle loans, card dealers, B2B businesses, beverages, wholesalers & many other industries</li>
                                    <li className="mb-2">You own branded Web & Mobile apps</li>
                                    <li className="mb-2">No installation, no integration needed</li>
                                    <li className="mb-2">Automate your collections process</li>
                                    <li className="mb-2">Increase loyalty, decrease bad debts, increase transparency</li>
                                </ul>
                                <a href="./website/assets/img/pdf/Thirdrocs-Digital-Lending-Platform_website.pdf" className="bnplPage brNone"><i className="fa-regular fa-file-pdf"></i> Digital Lending, Learn More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <section className="services bg-gradient--light p-top-100 p-bottom-40">
                <div className="container">
                    <div className="row align-item-enter justify-content-center">
                        <div className="col-md-10">
                            <h2 className="whyChooeNewHead"> How it Works</h2>
                            <p className="whyChoosePraNew">The process is straightforward and efficient. In just a few steps, you'll see how effortlessly it works.</p>
                        </div>
                    </div>

                    <div className="row mt-5">
                        <div className="icon-boxes icon-box--sixteen col-lg-12">
                            <div className="row">
                                <div className="col-lg-3 col-md-6">
                                    <div className="icon-box icon-box-15 text-center HeadingEWABox" style={{ padding: '2rem 1rem 0.5rem 1rem', minHeight: '20rem' }}>
                                        <img src="website/assets/img/FL-HW-1.png" alt="" />
                                        <p style={{ color: '#0ac391' }}>Lender's login credentials created in Thirdroc's AI Agents web system</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="icon-box icon-box-15 text-center HeadingEWABox" style={{ padding: '2rem 1rem 0.5rem 1rem', minHeight: '20rem' }}>
                                        <img src="website/assets/img/FL-HW-2.png" alt="" />
                                        <p style={{ color: '#0ac391' }}>Branded mobile app updated on play store.</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="icon-box icon-box-15 text-center HeadingEWABox" style={{ padding: '2rem 1rem 0.5rem 1rem', minHeight: '20rem' }}>
                                        <img src="website/assets/img/FL-HW-3.png" alt="" />
                                        <p style={{ color: '#0ac391' }}>Borrower download the mobile app & apply for loan.</p>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="icon-box icon-box-15 text-center HeadingEWABox" style={{ padding: '2rem 1rem 0.5rem 1rem', minHeight: '20rem' }}>
                                        <img src="website/assets/img/FL-HW-4.png" alt="" />
                                        <p style={{ color: '#0ac391', lineHeight: '1.5rem', textAlign: 'start' }}>Lender receives real-time notification & loan processed in 2 minutes - after AI agents execute all the loan processing steps one-by-one in a jiffy</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="features-area default-section">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-10">
                            <div className="my-title text-center">
                                <h4>Meant for customers who do not have tech infrastructure or have inadequate tech infrastructure in dealing with the risk assessment in various ways:</h4>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="card card-shadow card-four">
                                <figure><img src="./website/assets/img/c7.jpg" alt="c7" /></figure>
                                <div className="card-body">
                                    <h5><a href="#">Financial Sustainability</a></h5>
                                    <p className="card-text">Fintechs, Banks, NBFCs, Enterprises, Retail- Retailers, Distributors, Suppliers, Vendors in the Supply chain, eCommerce App Aggregators, Logistics &amp; Warehousing</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="card card-shadow card-four">
                                <figure><img src="./website/assets/img/c8.jpg" alt="c8" /></figure>
                                <div className="card-body">
                                    <h5><a href="#">Make Business Strategy</a></h5>
                                    <p className="card-text">No unnecessary cost or time wastage to setup this whole infrastructure. Take advantage of the existing stable secure tech infrastructure &amp; focus on offering services</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default DigitalAutomation;