import React, { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import Loader from '../../components/Loader/loader';
import { TimeZone } from '../../components/TimeZone/TimeZone';
import PaginationComponent from '../../PaginationComponent';
import { getApi } from '../service/adminService';
import { useNavigate } from 'react-router-dom';
const prefix = process.env.REACT_APP_ADMIN_PRIFIX;

const FailedReminderLogs = () => {

    const navigate = useNavigate();

    const [loader, setloader] = useState(true)
    const [isPinUpdated, setIsPinUpdated] = useState(false)
    const [ValidPagination, setValidPagination] = useState(false)

    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);

    const [Count, setCount] = useState(0);
    const [search, setSearch] = useState('');

    const [failedLogArray, setFailedLogArray] = useState([])

    const fetchFailedLogs = async () => {
        try {
            setloader(true)

            const response = await getApi(`/failed-reminder-logs`, { search: search, page_number: pagesCount })

            if (response.status) {

                setIsPinUpdated(response?.isPinUpdated)
                setFailedLogArray(response?.data?.rows)
                setTotalPagesCount(response?.data?.count)
                setCount(response?.data?.count)
                setloader(false)
            }

        } catch (error) {
            console.log("🚀 ~ fetchFailedLogs ~ error:", error)
            setloader(false)
        }
    }

    useEffect(() => {
        fetchFailedLogs()

    }, [search, pagesCount])

    const handleLoanApplication = (loan_id) => {
        navigate(`${prefix}/manage_pin/${loan_id}`, { state: { isPinUpdated, from: '/failed_reminder_logs' } });
    };

    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            <div>
                <div className="main-container container-fluid px-0">
                    <div className="page-header mb-3">
                        <div className="page-leftheader">
                            <h4 className="page-title mb-0 text-primary">Failed Reminder Logs ({Count})</h4>
                        </div>
                        <div className="page-rightheader">
                            <div className="btn-list">
                                {/* <button
                                    className="btn btn-primary btn-pill"
                                >
                                    <i className="fa fa-add me-2"></i>
                                    Add Reminder
                                </button> */}
                            </div>
                        </div>

                    </div>

                    <div className="row">

                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" />
                        <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                            <div className="form-group">
                                <label className="form-label">Search</label>
                                <div className="row g-xs">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search By Keyword"
                                            value={search}
                                            onChange={(e) => {
                                                setSearch(e.target.value);
                                                setpagesCount('1');
                                                setValidPagination(!ValidPagination)
                                            }}
                                        />
                                        <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive">
                                        <table className="table table-hover card-table table-vcenter text-nowrap">
                                            <thead className="border-bottom-0 pt-3 pb-3">
                                                <tr>
                                                    <th className="font-weight-bold">EMI Id</th>
                                                    <th className="font-weight-bold">Loan Id</th>
                                                    <th className="font-weight-bold">Borrower Detail</th>
                                                    <th className="font-weight-bold">Error</th>
                                                    <th className="font-weight-bold">Reminder</th>
                                                    <th className="font-weight-bold">Type</th>
                                                    <th className="font-weight-bold">Created Date</th>
                                                </tr>
                                            </thead>
                                            {loader == true
                                                ? <tbody>
                                                    <tr>
                                                        <td /><td />
                                                        <Loader />
                                                    </tr>
                                                </tbody>
                                                : <>
                                                    <tbody>
                                                        {failedLogArray.length > 0 && failedLogArray.map((item, index) => {
                                                            let serial_num = ((10 * (pagesCount - 1)) + index + 1);

                                                            return (
                                                                <tr key={serial_num} >
                                                                    <td><span className='font-weight-normal1'>{item?.emi_id}</span></td>
                                                                    <td>
                                                                        <span
                                                                            role="button"
                                                                            className="font-weight-normal1 text-info"
                                                                            onClick={() => handleLoanApplication(item?.loan_id)}
                                                                        >
                                                                            {item?.loan_id}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span className="font-weight-normal1">
                                                                            {item?.loanDetail?.first_name
                                                                                ? item?.loanDetail?.first_name + " " + item?.loanDetail?.last_name
                                                                                : "--"
                                                                            }
                                                                            <br />
                                                                            {item?.loanDetail?.mobile_number}
                                                                        </span>
                                                                    </td>
                                                                    <td><span className="font-weight-normal1 wrapCustomCss"> {item?.error} </span></td>
                                                                    <td><span className="font-weight-normal1 wrapCustomCss"> {item?.message} </span></td>
                                                                    <td><span className="font-weight-normal1"> {item?.type} </span></td>
                                                                    <td><span className="font-weight-normal1">{/* {timezone} */}{TimeZone(item?.created_at).timeZone}</span></td>
                                                                </tr>
                                                            )
                                                        }
                                                        )}
                                                    </tbody>
                                                    <tbody>
                                                        {failedLogArray.length == 0 &&
                                                            <tr>
                                                                <td colSpan={10} className='text-center'>No Data Available</td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </>}
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {pagesCount > 0 && failedLogArray.length > 0 && ValidPagination === true ?
                            <>
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <PaginationComponent className="justify-content-center"
                                            totalItems={totalPagesCount}
                                            pageSize={10}
                                            maxPaginationNumbers={3}
                                            onSelect={(e) => setpagesCount(e)}
                                        />
                                    </div>
                                </div>
                            </>
                            : ''}
                        {pagesCount > 0 && failedLogArray.length > 0 && ValidPagination === false ?
                            <>
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <PaginationComponent className="justify-content-center"
                                            totalItems={totalPagesCount}
                                            pageSize={10}
                                            maxPaginationNumbers={3}
                                            onSelect={(e) => setpagesCount(e)}
                                        />
                                    </div>
                                </div>
                            </>
                            : ''}


                    </div>

                </div>
            </div>
        </>
    );
}

export default FailedReminderLogs;
